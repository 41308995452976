import React, {useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";
import Header from "../Components/Header";
import Hero from "../Components/Hero";
import About from "../Components/About";
import HowItWorks from "../Components/HowItWorks";
import Benefits from "../Components/Benefits";
import Map from "../Components/Map";
import Footer from "../Components/Footer";
import API from "../Services/api"




const LandingPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false); // State for authentication status
    const [user, setUser] = useState(null);
  
    useEffect(() => {
      const checkAuthentication = async () => {
        try {
          const response = await API.get("/users/profile");
        console.log(response);
        setUser(response.data);
          if (response) {
            setUser(response.data); 
            console.log("user", user);
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error("Authentication check failed:", error);
          setIsAuthenticated(false);
        }
      };
      const images = Array.from(document.getElementsByTagName("img")); 
      const fonts = ["Inter", "Montserrat"];
  
      Promise.all([
        // Check if all images are loaded
        Promise.all(images.map((img) => {
          return new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
          });
        })),
        // Check if all fonts are loaded
        Promise.all(fonts.map((font) => {
          return document.fonts.load(`1em ${font}`);
        })),
      ])
      .then(() => {
        setIsLoading(false); 
      })
      .catch((error) => {
        console.error("Error loading resources:", error);
        setIsLoading(false); // Hide spinner on error as well
      });

      checkAuthentication();
    }, []);

  return (
    <div className="min-h-screen flex flex-col w-full">
      {isLoading ? ( 
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-white bg-opacity-75">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-[#29752B]"></div>
        </div>
      ) : (
        <>
          <Header isDashboard={false} isAuthenticated={isAuthenticated} user={user} />
          <Hero />
          <About />
          <HowItWorks />
          <Benefits />
          <Map />
          <Footer />
        </>
      )}

    </div>
  );
};

export default LandingPage;
