import React from "react";

const HowItWorks = () => {
  return (
    <section className="bg-white py-20 px-6 md:px-8 relative overflow-hidden" id="how-it-works" >
      <div className="container mx-auto">
        {/* How it works heading */}
        <div className="flex flex-col items-left mb-16">
          <img
            src="/images/double-down-arrow.png"
            alt="Double Down Arrow"
            className="w-16 h-auto mb-4"  data-aos="fade-down" data-aos-delay="100"
          />
          <h2 className="lg:absolute lg:left-[11%] lg:mt-5 text-3xl md:text-4xl font-bold "  data-aos="fade-up" data-aos-delay="200">
            How it <span className="text-[#59CBAC]">works</span>
          </h2>
        </div>

        <div className="flex flex-col md:flex-row md:space-x-16">
          {/* Left side: Steps */}
          <div className="w-full md:w-1/3 mb-10 md:mb-0 space-y-20 lg:ml-8 mt-8"> 
            {/* Step 1 */}
            <div className="space-y-3">
              <h3 className="text-2xl font-semibold text-black"  data-aos="fade-right" data-aos-delay="300">
                Locate a Collection Centre
              </h3>
              <p className="text-gray-700 text-lg" data-aos="fade-right" data-aos-delay="400">
                Use our Interactive E-Waste Map to find the nearest recycling
                center or drop-off point in your area.
              </p>
            </div>

            {/* Step 2 */}
            <div data-aos="fade-right" data-aos-delay="500">
              <img
                src="/images/empty-box.png"
                alt="Empty Box"
                className="w-80 h-auto mx-auto md:block hidden"
              />
            </div>
            <div data-aos="fade-right" data-aos-delay="600">
              <img
                src="/images/user_locate_collectioncentre.png"
                alt="User Locating Collection Centre"
                className="w-80 h-auto mx-auto md:hidden block" // Increased image size
              />
            </div>

            {/* Step 3 */}
            <div className="space-y-3">
              <h3 className="text-2xl font-semibold text-black" data-aos="fade-right" data-aos-delay="700">
                Get Paid or Receive Rewards
              </h3>
              <p className="text-gray-700 text-lg" data-aos="fade-right" data-aos-delay="800">
                Collection centers offer rewards or store credit for your items. Do
                your part for the planet and enjoy the rewards!
              </p>
            </div>
          </div>

          {/* Vertical line and numbers */}
          <div
            className="hidden md:flex md:flex-col items-center w-1 h-[880px] bg-[#59CBAC] relative" // Changed to flex-col and increased height
            style={{ left: "0", transform: "translateX(-50%)" }} data-aos="fade-left" data-aos-delay="300" // Centered the line
          >
            {[
              { top: "7%", number: 1 }, 
              { top: "38%", number: 2 }, 
              { top: "67%", number: 3 }, 
            ].map((item, index) => (
              <div
                key={index}
                style={{ top: item.top }} // Using inline styles for positioning
                className="absolute w-8 h-8 rounded-full bg-[#EEFAF7] border-2 border-[#255548] flex items-center justify-center"
              >
                <span className="text-[#255548] font-bold">
                  {item.number}
                </span>
              </div>
            ))}
          </div>

          {/* Right side: Images and descriptions */}
          <div className="w-full md:w-1/3 space-y-16 -mt-16"> 
            {/* Image 1 */}
            <div data-aos="fade-left" data-aos-delay="400">
              <img
                src="/images/user_locate_collectioncentre.png"
                alt="User Locating Collection Centre"
                className="w-80 h-auto mx-auto hidden md:block" // Increased image size
              />
            </div>
            <div data-aos="fade-left" data-aos-delay="400">
              <img
                src="/images/empty-box.png"
                alt="Empty Box"
                className="w-80 h-auto mx-auto md:hidden block"
              />
            </div>

            {/* Description 2 */}
            <div className="space-y-3">
              <h3 className="text-2xl font-semibold text-black" data-aos="fade-left" data-aos-delay="500">
                Drop Off Your Old Devices
              </h3>
              <p className="text-gray-700 text-lg" data-aos="fade-left" data-aos-delay="600">
                Bring in your old electronics—phones, laptops, chargers, or any
                other unwanted gadgets—to the selected center.
              </p>
            </div>

            {/* Image 3 */}
            <div data-aos="fade-left" data-aos-delay="700">
              <img
                src="/images/get-rewards.png"
                alt="Getting Rewards"
                className="w-80 h-auto mx-auto" // Increased image size
              />
            </div>
          </div>
        </div>
        <br className="lg:hidden"/>

        {/* Explore Map button */}
        <div className="lg:w-5/12 md:w-2/3 md:mx-auto lg:my-0 relative"  data-aos="fade-in" data-aos-delay="100">
          <button onClick={() => window.location.href = '/map'} className="w-full px-8 py-3 bg-[#59CBAC] text-[#263D42] rounded-full font-semibold flex items-center justify-center hover:bg-[#7de4af]">
            Explore Map
          </button>
        </div>

        {/* TV and recycle images */}
        <img
          src="/images/tv.png"
          alt="TV"
          className="hidden md:block absolute top-8 right-8 w-[220px] h-auto opacity-10 " // Increased image size
          data-aos="fade-left" data-aos-delay="400"/>
        <img
          src="/images/recycle-sign.png"
          alt="Recycle Sign"
          className="md:block hidden absolute bottom-8 right-8 w-[220px] h-auto opacity-50 " data-aos="fade-left" data-aos-delay="200" // Increased image size
        />
      </div>
    </section>
  );
};

export default HowItWorks;