import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Hero = () => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const [isDragging, setIsDragging] = useState(false);
  const autoDragInterval = useRef(null);
  let autoDragDirection = 1; 
  const sliderContainerRef = useRef(null);

  // Function to handle slider movement
  const handleSlider = (clientX, containerRect) => {
    const offsetX = clientX - containerRect.left;
    const newSliderPosition = (offsetX / containerRect.width) * 100;
    setSliderPosition(Math.max(0, Math.min(100, newSliderPosition)));
  };

  // Event handlers for dragging
  const startDragging = (e) => {
    e.preventDefault();
    setIsDragging(true);
    stopAutoDrag(); 
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const containerRect = sliderContainerRef.current.getBoundingClientRect();
    handleSlider(e.clientX, containerRect);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const containerRect = sliderContainerRef.current.getBoundingClientRect();
    handleSlider(e.touches[0].clientX, containerRect);
  };

  // Determine the background image based on the slider position
  const getImageBasedOnPosition = () => {
    if (sliderPosition < 40) return "/images/furniture_clear.png";
    if (sliderPosition > 60) return "/images/Furniture_rough.png";
    return "/images/Furniture_half_rough.png";
  };

  // Attach and detach event listeners for dragging
  useEffect(() => {
    const container = sliderContainerRef.current;

    if (isDragging) {
      container.addEventListener("mousemove", handleMouseMove);
      container.addEventListener("mouseup", stopDragging);
      container.addEventListener("touchmove", handleTouchMove);
      container.addEventListener("touchend", stopDragging);
    } else {
      container.removeEventListener("mousemove", handleMouseMove);
      container.removeEventListener("mouseup", stopDragging);
      container.removeEventListener("touchmove", handleTouchMove);
      container.removeEventListener("touchend", stopDragging);
    }

    return () => {
      // Clean up event listeners when component unmounts
      container.removeEventListener("mousemove", handleMouseMove);
      container.removeEventListener("mouseup", stopDragging);
      container.removeEventListener("touchmove", handleTouchMove);
      container.removeEventListener("touchend", stopDragging);
    };
  }, [isDragging]);

  // Function to start auto-dragging
  const startAutoDrag = () => {
    clearInterval(autoDragInterval.current);
    autoDragInterval.current = setInterval(() => {
      setSliderPosition((prevPosition) => {
        let newPosition = prevPosition + autoDragDirection * 1.5; 
        if (newPosition >= 100) {
          autoDragDirection = -1; 
          newPosition = 100;
        } else if (newPosition <= 0) {
          autoDragDirection = 1; 
          newPosition = 0;
        }
        return newPosition;
      });
    }, 35);

    setTimeout(() => {
      clearInterval(autoDragInterval.current); 
      autoDragInterval.current = null;
    }, 9600);
  };

  // Function to stop auto-dragging
  const stopAutoDrag = () => {
    clearInterval(autoDragInterval.current);
  };

  // Start auto-dragging after 1 seconds of inactivity
  useEffect(() => {
    let idleTimeout;
    const resetIdleTimeout = () => {
      clearTimeout(idleTimeout);
      idleTimeout = setTimeout(startAutoDrag, 1000);
    };

    resetIdleTimeout(); 

    const container = sliderContainerRef.current;
    container.addEventListener("mousemove", resetIdleTimeout);
    container.addEventListener("mousedown", stopAutoDrag);
    container.addEventListener("touchstart", stopAutoDrag);

    return () => {
      container.removeEventListener("mousemove", resetIdleTimeout);
      container.removeEventListener("mousedown", stopAutoDrag);
      container.removeEventListener("touchstart", stopAutoDrag);
      clearInterval(autoDragInterval.current);
    };
  }, []);


  return (
    <section className="bg-[#004d3f] text-white min-h-screen flex items-center justify-center px-6 md:px-8 py-2 overflow-hidden">
      <div className="container mx-auto flex flex-col md:flex-row items-start md:space-x-8">
        {/* Left Side Text Section */}
        <div className="text-center md:mx-5 md:text-left md:w-1/3 md:my-auto space-y-4">
          <p className=" text-3xl tracking-wider text-gray-200 mb-3" data-aos="fade-right" data-aos-delay="100">
            <span className="text-yellow-500 text-4xl md:text-4xl font-bold">Discover</span>
            <br /> creative ways to repurpose old or used electronics <br /> <span className="relative z-10">and still</span>{" "}
            <br />
            <br className="md:hidden" />

            <h1 className="relative inline-block text-3xl md:text-4xl font-bold leading-tight md:float-right" data-aos="fade-right" data-aos-delay="400">
              {/* SVG Background */}
              <img
                src="/images/Vector 1.svg" 
                alt="Decorative circular line around 'get paid'"
                className="absolute w-[280px] h-[280px] left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0 animate-pulse"
              />
              {/* Text in Center */}
              <span className="relative z-10 text-[#8df5c3] px-4">get rewards</span> 
            </h1>
          </p>
          <br className="hidden md:block" />
          <br className="hidden md:block" />
          <br className="hidden md:block" />
          <br />

          <button onClick={() => window.location.href = '/map'} className="w-full md:w-auto px-6 py-3 pr-2 bg-[#59CBAC] text-[#004d3f] rounded-full font-semibold flex items-center text-[22px] justify-center hover:bg-[#7de4af] relative" data-aos="fade-right" data-aos-delay="300">
            <span className="mr-3">Explore Map</span>
            <div className="bg-[#ffffff] p-3 rounded-full ml-3"> 
              <FontAwesomeIcon icon={faArrowRight} className="w-6 h-5 text-[#004d3f] -rotate-45" />
            </div>
          </button>
        </div>
        <br className="mb:hidden" />
        {/* Placeholder for 305x538 Image */}
        <div className="w-full md:w-[305px] h-[538px] rounded-lg shadow-md flex items-center justify-center" data-aos="fade-down" data-aos-delay="200">
          <img
            src="/images/e-waste-recycled.jpg"
            alt="E-waste Recycled"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>

        {/* Image Section with Before-After Slider */}
        <div
          className="relative slider-container mt-8 md:mt-0 w-full md:w-[538px] h-[538px]"
          onMouseDown={startDragging}
          onTouchStart={startDragging}
          ref={sliderContainerRef}
          data-aos="fade-left"
          data-aos-delay="300"
        >
          {/* Rough Image */}
          <img
            src="/images/furniture_clear.png"
            alt="Rough Furniture"
            className="rounded-lg shadow-lg w-full h-full object-cover"
          />
          {/* Clear Image as Overlay */}
          <img
            src="/images/Furniture_rough.png"
            alt="Clear Furniture"
            className="rounded-lg shadow-lg w-full h-full object-cover absolute top-0 left-0"
            style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
          />
          {/* Draggable Slider Line */}
          <div
            className="absolute top-0 bottom-0 flex items-center"
            style={{ left: `${sliderPosition}%` }}
          >
            <div className="h-full w-1 bg-white"></div>
            <div
              className="w-6 h-6 bg-white rounded-full -mt-3 -ml-3 cursor-pointer"
              onMouseDown={startDragging}
              onTouchStart={startDragging}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
