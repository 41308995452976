// src/components/Rewards/RedeemButton.js
import { useState } from 'react';
import API from '../../Services/api';

function RedeemButton({ rewardId, pointsRequired }) {
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const handleRedeem = async () => {
    try {
      const response = await API.post('/rewards/redeem', { rewardId });
      setMessage(response.data.message);
    } catch (err) {
      setError(err.response?.data?.error || 'Error redeeming reward');
    }
  };

  return (
    <div className="mt-4">
      <button 
        onClick={handleRedeem} 
        className="bg-blue-500 text-white py-2 px-4 rounded">
        Redeem Reward
      </button>
      {message && <p className="text-green-500 mt-2">{message}</p>}
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
}

export default RedeemButton;
