import React, { useState, useEffect } from 'react';
import API from '../../Services/api';

const RecyclingRequests = ({ requests }) => {
  const [editedRequests, setEditedRequests] = useState({});

  const defaultWeights = {
    "Mobile Phone": 0.2,
    "Laptop": 2.5,
    "Monitor": 5.0,
  };

  const conditionMultipliers = {
    New: 1,
    Used: 0.7,
    Damaged: 0.4,
  };

  const calculatePoints = (request) => {
    const weight = request.weight || defaultWeights[request.deviceType] || 1;
    const basePoints = request.basePoints || 10;
    const conditionMultiplier = conditionMultipliers[request.deviceCondition] || 1;
    return Math.round(basePoints * conditionMultiplier * weight * request.quantity);
  };

  const calculateEnvironmentalImpact = (request) => {
    const weightFactor = request.weight || defaultWeights[request.deviceType] || 1;
    const quantityFactor = request.quantity;
    const conditionImpact = conditionMultipliers[request.deviceCondition] || 1;
    return Math.min(5, Math.max(1, Math.round(weightFactor * quantityFactor * conditionImpact / 2)));
  };

  const handleInputChange = (requestId, field, value) => {
    const updatedRequest = {
      ...editedRequests[requestId],
      [field]: value,
    };
  
    // Recalculate points and environmental impact based on updated fields
    updatedRequest.pointsEarned = calculatePoints(updatedRequest);
    updatedRequest.environmentalImpact = calculateEnvironmentalImpact(updatedRequest);
  
    setEditedRequests((prev) => ({
      ...prev,
      [requestId]: updatedRequest,
    }));
  };
  

  const handleUpdateStatus = async (requestId, newStatus) => {
    const updatedRequestData = editedRequests[requestId] || requests.find(r => r._id === requestId);

    if (updatedRequestData) {
      updatedRequestData.pointsEarned = calculatePoints(updatedRequestData);
      updatedRequestData.environmentalImpact = calculateEnvironmentalImpact(updatedRequestData);

      try {
        await API.patch(`/collection-staff/requests/${requestId}/handle`, {
          status: newStatus,
          updatedDetails: updatedRequestData,
        });
        alert('Status updated successfully');
      } catch (error) {
        console.error('Failed to update status', error);
        alert('Error updating status');
      }
    }
  };

  return (
    <div className="bg-white p-6 rounded-md shadow-md">
      <h3 className="text-2xl font-semibold mb-4">Recycling Requests</h3>
      <ul className="space-y-4">
        {requests.map((request) => (
          <li key={request._id} className="border rounded-md p-4">
            <div className="mb-2">
              <label>Item Type:</label>
              <input
                type="text"
                value={editedRequests[request._id]?.deviceType || request.deviceType}
                onChange={(e) => handleInputChange(request._id, 'deviceType', e.target.value)}
                className="border p-1 rounded w-full"
              />
            </div>
            <div className="mb-2">
              <label>Brand Model:</label>
              <input
                type="text"
                value={editedRequests[request._id]?.brandModel || request.brandModel || ''}
                onChange={(e) => handleInputChange(request._id, 'brandModel', e.target.value)}
                className="border p-1 rounded w-full"
              />
            </div>
            <div className="mb-2">
              <label>Quantity:</label>
              <input
                type="number"
                value={editedRequests[request._id]?.quantity || request.quantity || 0}
                onChange={(e) => handleInputChange(request._id, 'quantity', e.target.value)}
                className="border p-1 rounded w-full"
              />
            </div>
            <div className="mb-2">
              <label>Condition:</label>
              <select
                value={editedRequests[request._id]?.deviceCondition || request.deviceCondition}
                onChange={(e) => handleInputChange(request._id, 'deviceCondition', e.target.value)}
                className="border p-1 rounded w-full"
              >
                <option value="New">New</option>
                <option value="Used">Used</option>
                <option value="Damaged">Damaged</option>
              </select>
            </div>
            <div className="mb-2">
              <label>Weight (in kg):</label>
              <input
                type="number"
                step="0.1"
                value={editedRequests[request._id]?.weight || request.weight || defaultWeights[request.deviceType]}
                onChange={(e) => handleInputChange(request._id, 'weight', e.target.value)}
                className="border p-1 rounded w-full"
              />
            </div>
            <div className="mb-2">
              <label>Points Earned:</label>
              <input
                type="text"
                value={editedRequests[request._id]?.pointsEarned || calculatePoints(request)}
                readOnly
                className="border p-1 rounded w-full"
              />
            </div>
            <div className="mb-2">
              <label>Environmental Impact Score:</label>
              <input
                type="text"
                value={editedRequests[request._id]?.environmentalImpact || calculateEnvironmentalImpact(request)}
                readOnly
                className="border p-1 rounded w-full"
              />
            </div>
            <div className="flex gap-4">
              <button
                onClick={() => handleUpdateStatus(request._id, 'Processing')}
                className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
              >
                Set as Processing
              </button>
              <button
                onClick={() => handleUpdateStatus(request._id, 'Completed')}
                className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
              >
                Set as Completed
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecyclingRequests;
