import React, { useState, useEffect } from "react";
import API from "../Services/api";
// import { Tooltip } from "react-tooltip";
import UserProfile from "./Dashboard/UserProfile";
import DashboardSummary from "./Dashboard/DashboardSummary";
import Dashboard from "./Dashboard/Dashboard";
import RecyclingHistory from "./Dashboard/RecyclingHistory";
import Header from "./Header";
import Footer from "./Footer";

function Profile() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const [showError, setShowError] = useState(false); 

  useEffect(() => {
    let timeOutId;
    const images = Array.from(document.getElementsByTagName("img")); 
    const fonts = ["Inter", "Montserrat"];
    const fetchProfile = async () => {
      try {
        const response = await API.get("/users/profile");
        console.log(response);
        setUser(response.data);
        Promise.all([
          // Check if all images are loaded
          Promise.all(images.map((img) => {
            return new Promise((resolve, reject) => {
              img.onload = resolve;
              img.onerror = reject;
            });
          })),
          // Check if all fonts are loaded
          Promise.all(fonts.map((font) => {
            return document.fonts.load(`1em ${font}`);
          })),
        ])
      } catch (error) {
        console.error("Error fetching profile", error);
        clearTimeout(timeOutId); // Clear timeout if loading fails
        setShowError(true); 
      } finally {
        clearTimeout(timeOutId); // Clear timeout if loading fails
        setIsLoading(false); // Set isLoading to false after fetching
      }
    };
    fetchProfile();
    // Set a timeout for 10 seconds
    timeOutId = setTimeout(() => {
      setIsLoading(false);
      setShowError(true); // Show error message if loading takes too long
    }, 15000); 
    return () => clearTimeout(timeOutId); 
  }, []);
  const hasRecyclingHistory = user?.recyclingHistory?.length > 0;
  return (
    <div className="min-h-screen w-full flex flex-col bg-[#F8FAFB]">
      {isLoading ? ( // Conditionally render the loading spinner
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-white bg-opacity-75">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-[#29752B]"></div>
        </div>
      )  : showError ? (
        <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-[#F8FAFB]">
          <img src="/images/internet-con.png" alt="No internet" className="h-24 w-24 mb-4" /> 
          <h3 className="text-xl font-bold text-[#172A3A] mb-2">Oops! Something went wrong.</h3>
          <p className="text-[#414042] text-center">
            Please check your internet connection and try again.
          </p>
          <button 
            onClick={() => window.location.reload()} // Reload the page on button click
            className="bg-[#29752B] text-white px-6 py-3 rounded-lg mt-6 hover:bg-[#18421B]"
          >
            Retry
          </button>
        </div>
      ) : (
        <>
        <Header isDashboard={true} user={user} isAuthenticated={true} />

        <main className="container mx-auto px-4 sm:px-6 lg:px-8 py-12 flex-grow">
          <div className="flex flex-col lg:flex-row lg:space-x-4">
            {/* Left side */}
            <div className="w-full lg:w-1/3 mb-8 lg:mb-0 z-[3]">
              <div className="h-full w-full flex flex-col">
                <UserProfile user={user} />
              </div>
            </div>

            {/* Right side */}
            <div className="w-full lg:w-2/3 bg-[#EBEDF0] p-6 md:p-0  rounded-3xl shadow-sm" data-aos="fade-in" data-aos-delay="200">
              <div className="h-full flex flex-col">
                <DashboardSummary user={user} />
              </div>
            </div>
          </div>
          
        </main>
        
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex flex-col lg:flex-row lg:space-x-8">
            {/* Below Main Container */}
            {hasRecyclingHistory ? (
            <>
              {/* Main Container */}
              <div className="w-full lg:w-3/4 mx-auto">
                <div className="mb-8">
                  <Dashboard user={user} />
                </div>
                <RecyclingHistory user={user} />
              </div>
            </>
          ) : (
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="w-full mx-auto lg:w-3/4 p-16">
              <div className="items-center  md:p-10">
                  <p className="text-lg text-center text-gray-600 mb-2"data-aos="fade-up"
                            data-aos-delay="0">No records found</p>
                  <p className="text-sm text-center text-gray-500 mb-6"data-aos="fade-up"
                            data-aos-delay="200">
                    Search for a collection centre to get started
                  </p>
                  <div className="flex justify-center">
                    <button className="flex md:items-center bg-[#398F3E] text-white px-6 md:px-28 py-2 rounded-md hover:bg-[#206A29]"
                            data-aos="fade-up"
                            data-aos-delay="400"
                            onClick={()=>{window.location.href = "/map";}}
                          >
                            <img
                          src="/images/globe.png"
                          alt="Globe icon"
                          className="w-5 h-5 mr-2"
                            />
                            Go to Map
                    </button>
                  </div>
              </div>
            </div>
            </div>
          )}
          </div>
        </div>

        <Footer />
      </>
    )}
      
    </div>
  );
}

export default Profile;
