import React, {useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
const Footer = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          once: false,
          offset: 20,
        });
      }, []);
  return (
    <footer className="bg-[#101828] py-8 px-6 md:px-8">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
        <p className="text-[#98A2B3] text-sm text-center md:text-left"  data-aos="fade-right" data-aos-delay="100">
          © 2024 e-save. All rights reserved.
        </p>
        <div className="flex space-x-6 justify-center md:justify-end"> {/* Social icons container */}
          <a href="#" className="text-[#98A2B3] text-xl hover:text-white transition duration-200"  data-aos="fade-right" data-aos-delay="200">
            <i className="fab fa-twitter"></i> {/* Twitter icon */}
          </a>
          <a href="#" className="text-[#98A2B3] text-xl hover:text-white transition duration-200"  data-aos="fade-right" data-aos-delay="300">
            <i className="fab fa-linkedin"></i> {/* LinkedIn icon */}
          </a>
          <a href="#" className="text-[#98A2B3] text-xl hover:text-white transition duration-200"  data-aos="fade-right" data-aos-delay="400">
            <i className="fab fa-facebook"></i> {/* Facebook icon */}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
