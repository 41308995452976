import React, { useState, useEffect } from "react";
import RecyclingSubmissionPopup from "./RecyclingSubmissionPopup";
import CountUpWrapper from "../../CountUpWrapper";
import { Tooltip } from "react-tooltip";
import API from "../../Services/api"

const SubmitRequestPopup = ({ onClose, collectionPoint, isBookmarked, updateBookmarks }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [showRecyclingPopup, setShowRecyclingPopup] = useState(false);
    const [visibleCount, setVisibleCount] = useState(4);
    const [bookmarkStatus, setBookmarkStatus] = useState(isBookmarked);
    
    // Form state to capture user input
    const [requestData, setRequestData] = useState({
      itemType: "",
      weight: 0.5,
      quantity: 1,
      condition: "New",
      brandModel: "",
      estimatedPoints: 0,
      environmentalImpact: 0
    });
  
    // Default values and multipliers for calculations
    const defaultWeights = {
      "phone": 0.2,
      "laptop": 2.5,
    };
  
    const conditionMultipliers = {
        New: 1,
        Used: 0.7,
        Damaged: 0.4,
      };
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % 3); // 3 is the number of slides
      }, 4000); // Change slide every 4 seconds
      return () => clearInterval(interval);
    }, []);
  
    // Function to calculate estimated points based on item data
    const calculateEstimatedPoints = () => {
      const { itemType, weight, condition, quantity } = requestData;
      const itemWeight = weight || defaultWeights[itemType] || 1;
      const basePoints = collectionPoint.acceptedItems.find(i => i.name === itemType)?.points || 10;
      const conditionMultiplier = conditionMultipliers[condition] || 1;
      return Math.round(basePoints * conditionMultiplier * itemWeight * quantity);
    };
    const calculateEnvironmentalImpact = () => {
        const { itemType, weight, condition, quantity } = requestData;
        const weightFactor = weight || defaultWeights[itemType] || 1;
        const quantityFactor = quantity;
        const conditionImpact = conditionMultipliers[condition] || 1;
    
        // Environmental impact score calculation, ensuring it's a whole number between 1 and 5
        const impactScore = Math.min(5, Math.max(1, Math.round(weightFactor * quantityFactor * conditionImpact / 2)));
        return impactScore;
      };
  
    const estimatedPoints = calculateEstimatedPoints();
    const environmentalImpact = calculateEnvironmentalImpact();
  
    const handleInputChange = (field, value) => {
      setRequestData((prev) => ({ ...prev, [field]: value }));
    };
  
    // Function to open RecyclingSubmissionPopup
    const handleOpenRecyclingPopup = () => {
      setShowRecyclingPopup(true);
    };
    const handleToggleVisibility = (isIncreasing) => {
        if (isIncreasing) {
          setVisibleCount((prevCount) => Math.min(prevCount + 4, collectionPoint.acceptedItems.length));
        } else {
          setVisibleCount(4); // reset to default
        }
      };
    const visibleItems = collectionPoint.acceptedItems.slice(0, visibleCount);
    const handleGetDirections = ([longitude, latitude]) => {
      const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
      window.open(url, '_blank');
    };
    const handleBookmarkToggle = async () => {
    const token = localStorage.getItem('token');
      try {
        if (bookmarkStatus) {
          // Remove bookmark
          const response = await API.delete(
            `/users/bookmark/${collectionPoint._id}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          alert(response.data.message || "Location removed from bookmarks!");
        } else {
          // Add bookmark
          const response = await API.post(
            "/users/bookmark",
            { collectionPointId: collectionPoint._id },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          alert(response.data.message || "Location bookmarked successfully!");
        }
  
        // Update state and trigger parent update
        setBookmarkStatus(!bookmarkStatus);
      } catch (error) {
        console.error("Error updating bookmark:", error);
        alert(error.response?.data?.error || "Failed to update bookmark.");
      }
    };
  
  return (
    <div className="fixed inset-0 flex items-start justify-start z-[9999] bg-slate-400 bg-opacity-30">
      <div className="bg-white w-80 ml-3 md:w-[400px]  lg:w-[450px] md:ml-[7rem] mt-[4.1rem] p-6 rounded-3xl " style={{ maxHeight: "calc(100vh - 5rem)" }}>
        {/* Header */}
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-bold text-[#34A853]">{collectionPoint.name}</h3>
          <button onClick={onClose} className="close text-[#6E7D80] text-xl font-bold">
            X
          </button>
          <Tooltip anchorSelect=".close" place="top" style={{zIndex: 10000}}>
            Close
          </Tooltip>
        </div>

        <div className="w-full relative pr-6 overflow-x-hidden overflow-y-auto custom-scrollbar" style={{ maxHeight: "calc(100vh - 12rem)" }}>
        {/* Carousel Dots */}
        <div className="flex justify-center space-x-4 mb-6">
          {[0, 1, 2].map((dot) => (
            <div
              key={dot}
              className={`h-[7px] w-[100px] rounded-full bg-opacity-50  ${
                dot === currentSlide ? "bg-[#34A853]" : "bg-[#D9D9D9]"
              }`}
            ></div>
          ))}
        </div>
        {/* Carousel Images */}
        <div className="md:w-full overflow-hidden mx-auto mb-2 mt-1"> {/* Added mx-auto and mb-6 */}
              <div className="flex transform transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                <img src="/images/img_carousel1.png" alt="Carousel 1" className="w-full h-full object-cover shrink-0" />
                <img src="/images/img_carousel2.png" alt="Carousel 2" className="w-full h-full object-cover shrink-0" />
                <img src="/images/img_carousel3.png" alt="Carousel 3" className="w-full h-full object-cover shrink-0" />
              </div>
              {/* Save Collection Point Button */}
                <button
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={bookmarkStatus ? "Remove from favourites" : "Add to favourites"}
                  data-tooltip-place="top"
                  onClick={handleBookmarkToggle}
                  className={bookmarkStatus? "absolute top-6 right-6 p-3 rounded-full shadow-lg z-[9999] bg-[black]":"absolute top-6 right-6 p-3 rounded-full shadow-lg z-[9999] bg-none"}
                  aria-label={bookmarkStatus ? "Remove Bookmark" : "Bookmark Location"}
                >
                  <img                   
                    src="/images/material-symbols_bookmark.png"
                    alt={bookmarkStatus ? "Remove Bookmark Icon" : "Bookmark Icon"}
                    className="w-4 h-5 shadow-md"
                  />
                </button>
              <Tooltip id="my-tooltip"/>
        </div>

        {/* Center Details */}
        <div className="space-y-4 text-sm">
          <div className="flex items-center">
            <img src="/images/location-icon3.png" alt="Location" className="w-5 h-5 mr-2" />
            <span className="text-[#31705F] font-medium">Location:</span>
            <span className="text-[#000000] ml-1">{collectionPoint.address}</span>
          </div>
          <div className="flex items-center">
            <img src="/images/dollar-square.png" alt="Offers" className="w-5 h-5 mr-2" />
            <span className="text-[#31705F] font-medium">Offers:</span>
            <span className="text-[#000000] ml-1">Rewards for E-Waste</span>
          </div>
          <div className="flex items-center">
            <img src="/images/clock-icon.png" alt="Hours" className="w-5 h-5 mr-2" />
            <span className="text-[#31705F] font-medium">Hours:</span>
            <span className="text-[#000000] ml-1">Mon - Fri: 9 AM - 5 PM</span>
          </div>
          <div className="flex items-start">
            <img src="/images/devices-icon.png" alt="Devices" className="w-5 h-5 mr-2" />
            <span className="text-[#31705F] font-medium">Accepted Devices:</span>
            <div className="text-[#000000] ml-1">
                {visibleItems.map((item, index) => (
                <span key={index} className="">{item.name}{index < visibleItems.length - 1 && " |"} </span>
                ))}
                <div className="">
                  {visibleCount < collectionPoint.acceptedItems.length ? (
                  <button
                      onClick={() => handleToggleVisibility(true)}
                      className="text-[#31705F] hover:underline text-sm ml-1"
                  >
                      See More
                  </button>
                  ) : visibleCount > 4 ? (
                  <button
                      onClick={() => handleToggleVisibility(false)}
                      className="text-[#31705F] hover:underline text-sm ml-1"
                  >
                      See Less
                  </button>
                  ) : null}
              </div>
            </div>
            
            </div>
          <div className="flex items-center">
            <img src="/images/call-icon.png" alt="Contact" className="w-5 h-5 mr-2" />
            <span className="text-[#31705F] font-medium">Contact:</span>
            <span className="text-[#000000] ml-1">080 123 456 8743</span>
          </div>
          <div className="flex items-center">
            <img src="/images/sms-tracking.png" alt="Email" className="w-5 h-5 mr-2" />
            <span className="text-[#31705F] font-medium">Email:</span>
            <span className="text-[#000000] ml-1">info@greentech.com</span>
          </div>
        </div>

        {/* Get Directions Button */}
        <button 
          className="bg-[#34A853] text-[#263D42] font-medium py-3 px-6 rounded-full w-full mt-6 hover:bg-[#206A29]"
          onClick={() => handleGetDirections(collectionPoint.location.coordinates)}
        >
          Get Directions
        </button>


        {/* Separator */}
        <hr className="my-6 border-t border-gray-300" />

        {/* Reward Estimate Form */}
        <div className="space-y-4">
          <h4 className="text-[#34A853] font-medium">Get Your <span className="text-[#34A853] font-medium">Reward Estimate</span></h4>
          <p className="text-sm text-[#000000]">
            See how much your old devices are worth for recycling.
          </p>
          <div className="space-y-2">
            <div>
              <label htmlFor="deviceType" className="block text-[#414042] text-sm font-medium mb-1">
                Select Device Type
              </label>
              <select
                id="deviceType"
                value={requestData.itemType}
                onChange={(e) => handleInputChange("itemType", e.target.value)}
                className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-[#29752B] text-sm"
              >
                <option value="">Select</option>
                {collectionPoint.acceptedItems.map((acceptedItem) => (
                      <option key={acceptedItem.name} value={acceptedItem.name}>
                        {acceptedItem.name}
                      </option>
                    ))}
              </select>
            </div>
            <div>
              <label htmlFor="deviceCondition" className="block text-[#414042] text-sm font-medium mb-1">
                Select Device Condition
              </label>
              <select
                id="deviceCondition"
                value={requestData.condition}
                onChange={(e) => handleInputChange("condition", e.target.value)}
                className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-[#29752B] text-sm"
              >
                <option value="">Select</option>
                <option value="New">New</option>
                <option value="Used">Used</option>
                <option value="Damaged">Damaged</option>
              </select>
            </div>
            <div>
              <label htmlFor="deviceBrand" className="block text-[#414042] text-sm font-medium mb-1">
                Device Brand/Model
              </label>
              <input
                type="text"
                id="deviceBrand"
                value={requestData.brandModel}
                placeholder="e.g. Apple or Apple iPhone 12"
                onChange={(e) => handleInputChange("brandModel", e.target.value)}
                className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-[#29752B] text-sm"
              />
            </div>
            <div>
                <label htmlFor="weight">Weight (in kg)</label>
                <input
                    type="number"
                    id="weight"
                    min={0.1}
                    max={1000}
                    value={requestData.weight}
                    onChange={(e) => handleInputChange("weight", parseFloat(e.target.value))}
                    className="w-full border px-3 py-2 rounded-md"
                />
            </div>
            <div>
                <label htmlFor="quantity">Quantity</label>
                <input
                    type="number"
                    min={0}
                    max={20}
                    id="quantity"
                    value={requestData.quantity}
                    onChange={(e) => handleInputChange("quantity", parseInt(e.target.value))}
                    className="w-full border px-3 py-2 rounded-md"
                />
            </div>
          </div>
          <div className="mt-2 border-t border-gray-200 pt-4">
            <p className="text-[#414042] text-sm">
              Your estimated reward points value: <span className="text-lg font-bold text-[#18421B]"><CountUpWrapper start={0} end={estimatedPoints} duration={2} redraw={true} />pts</span>
            </p>
            <p className="text-[#FF6666] text-xs mt-2">
              *Estimate may vary based on device condition and market prices. Final offer provided by collection center.
            </p>
          </div>
        </div>

        {/* Get Offer Button */}
        <button className="bg-[#34A853] text-[#263D42] font-medium py-3 px-6 rounded-full w-full mt-6 hover:bg-[#206A29]" onClick={handleOpenRecyclingPopup}>
          Place Recycling Request
        </button>
        </div>
      </div>
       {/* RecyclingSubmissionPopup */}
        {showRecyclingPopup && (
            <RecyclingSubmissionPopup
            collectionPoint={collectionPoint}
            closePopup={() => setShowRecyclingPopup(false)}
            initialData={{ ...requestData }} // Pass only necessary fields
            estimatedPoints={estimatedPoints}
            environmentalImpact={environmentalImpact}
            />
        )}
    </div>
  );
};

export default SubmitRequestPopup;