import React, { useState, useEffect } from "react";
import API from "../../Services/api";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);


    useEffect(() => {
      const images = Array.from(document.getElementsByTagName("img")); 
      const fonts = ["Inter", "Montserrat"];
  
      Promise.all([
        // Check if all images are loaded
        Promise.all(images.map((img) => {
          return new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
          });
        })),
        // Check if all fonts are loaded
        Promise.all(fonts.map((font) => {
          return document.fonts.load(`1em ${font}`);
        })),
      ])
      .then(() => {
        setIsLoading(false); 
      })
      .catch((error) => {
        console.error("Error loading resources:", error);
        setIsLoading(false); // Hide spinner on error as well
      });
    }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % 3); // 3 is the number of slides
    }, 4000); // Change slide every 4 seconds
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
      offset: 120,
    });
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await API.post("/users/login", formData);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("role", response.data.role);
      localStorage.setItem("userId", response.data.userId);
      var staffToken = localStorage.getItem('staff-token');
      if(staffToken)localStorage.removeItem('staff-token');
      // Check for pending recycling request
    const recyclingRequest = localStorage.getItem("pendingRecyclingRequest");
    const requestExpiry = localStorage.getItem("requestExpiry");

    if (recyclingRequest && Date.now() < requestExpiry) {
      // Clear the pending request from localStorage
      localStorage.removeItem("pendingRecyclingRequest");
      localStorage.removeItem("requestExpiry");

      // Redirect to recycling submission popup with pre-filled data
      // const parsedRequest = JSON.parse(recyclingRequest);
      // const { collectionPointId, items } = parsedRequest;
      // Signal the original tab that the login is complete
      if (window.opener) {
        window.opener.postMessage("loginComplete", "*");
      }

      // Close the login tab
      window.close();
    } else {
      window.location.href = "/profile"; // Default redirection
    }
    } catch (err) {
      setError(err.response?.data?.error || "Error logging in");
    }
  };

  return (
    <section className="bg-gray-100 font-Inter py-12 px-6 md:px-12 min-h-screen flex flex-col lg:flex-row items-center justify-center">
      {isLoading ? ( 
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-white bg-opacity-75">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-[#29752B]"></div>
        </div>
      ) : (
      <div className="container mx-auto flex flex-col md:flex-row items-center overflow-hidden bg-white rounded-3xl py-8">
        {/* Form Section */}
        <div className="p-8 lg:w-1/3 mb-12 lg:mb-0" data-aos="fade-right" data-aos-delay="100">
          {/* Logo Section */}
          <div className="flex items-center justify-center mb-6">
            <a href="/">
              <img src="/images/second_logo.png" alt="e-save Logo" className="h-10 lg:h-10" />
            </a>
          </div>
          <h2 className="text-3xl font-bold text-gray-800 text-center mb-6">Welcome back</h2>
          <p className="text-gray-600 text-center mb-8">Sign in to access your account</p>
          {error && <div className="text-red-500 text-center mb-4">{error}</div>}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <img src="/images/Mail_icon.png" alt="Mail icon" className="absolute top-1/2 left-4 transform -translate-y-1/2 h-5 w-5" />
              <input
                type="email"
                id="email"
                name="email"
                className="w-full h-[45px] border border-gray-400 pl-12 pr-4 py-3 rounded-lg focus:outline-none focus:border-[#398F3E] focus:ring-1 focus:ring-[#398F3E]"
                placeholder="Email e.g.name@mail.com"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="relative">
              <img src="/images/Password_icon.png" alt="Password icon" className="absolute top-1/2 left-4 transform -translate-y-1/2 h-6 w-6" />
              <input
                type="password"
                id="password"
                name="password"
                className="w-full h-[45px] border border-gray-400 pl-12 pr-4 py-3 rounded-lg focus:outline-none focus:border-[#398F3E] focus:ring-1 focus:ring-[#398F3E]"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            {/* Show Password Checkbox */}
            <div className="mt-2 flex items-center txrt">
            <input
                type="checkbox"
                id="showPassword"
                checked={showPassword}
                onChange={() => setShowPassword((prev) => !prev)} // Toggle showPassword state
                className="mr-2 h-4 w-4 border-2 border-[#3f3f3f] rounded appearance-none cursor-pointer focus:ring-2 focus:ring-blue-500 checked:bg-[#398F3E] checked:border-[#398F3E] relative"
                style={{ position: "relative" }}
              />

              <label htmlFor="showPassword" className="text-gray-600 text-sm">
                Show Password
              </label>
            </div>
            <div className="text-right">
              <a href="#" className="text-[#398F3E] hover:underline">
                Forgot Password?
              </a>
            </div>
            <button
              type="submit"
              className="w-full bg-[#398F3E] text-white font-medium py-3 rounded-lg hover:bg-[#206A29] focus:outline-none"
            >
              Sign in
            </button>

            {/* "or" separator */}
            <div className="relative">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-white px-2 text-gray-500 z-10">or</span>
              </div>
            </div>

            {/* <button className="w-full bg-gray-100 border border-gray-200 flex items-center justify-center py-3 rounded-lg hover:bg-gray-300">
              <img src="/images/Google_logo.png" alt="Google logo" className="h-6 mr-2" />
              <span className="font-bold">Sign in with Google</span>
            </button> */}
            <div className="text-center">
              <p className="text-gray-800">
                Don't have an account? 
                <a href="/register" className="text-[#398F3E] hover:underline">
                  Sign up
                </a>
              </p>
            </div>
          </form>
        </div>

        {/* Image Carousel Section */}
        <div className="w-full lg:w-2/3 relative hidden md:block"data-aos="fade-left" data-aos-delay="200">
          <div className="w-full md:w-[500px] lg:w-[721px] h-[300px] md:h-[500px] lg:h-[724px] relative overflow-hidden rounded-3xl shadow-md mx-auto bg-[#f3f4f6]">
            {/* Carousel Images */}
            <div className="md:w-[455px] md:h-[302px] overflow-hidden mx-auto mb-6 mt-3 md:mt-20"> {/* Added mx-auto and mb-6 */}
              <div className="flex transform transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                <img src="/images/img_carousel1.png" alt="Carousel 1" className="w-full h-full object-cover shrink-0" />
                <img src="/images/img_carousel2.png" alt="Carousel 2" className="w-full h-full object-cover shrink-0" />
                <img src="/images/img_carousel3.png" alt="Carousel 3" className="w-full h-full object-cover shrink-0" />
              </div>
            </div>

            {/* Text Overlay */}
            <div className="md:absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-full">
              <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold text-black mb-4">Recycle, Track and Earn</h3>
              <p className="text-black mb-6">Locate nearby e-waste centres</p>
              {/* Carousel Dots */}
              <div className="flex justify-center space-x-3">
                {[0, 1, 2].map((dot) => (
                <span key={dot} className={`h-2 w-2 rounded-full inline-block ${dot === currentSlide ? "bg-gray-800" : "bg-gray-400"}`}></span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </section>
  );
};

export default Login;
