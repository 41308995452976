import React, { useEffect, useState } from 'react';
import API from '../../Services/api';
import RecyclingRequests from './RecyclingRequests';

const StaffDashboard = () => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await API.get('/collection-staff/requests');
        setRequests(response.data);
      } catch (error) {
        console.error('Failed to fetch requests', error);
      }
    };

    fetchRequests();
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <h2 className="text-3xl font-semibold text-gray-800 mb-6">Dashboard</h2>
      <RecyclingRequests requests={requests} />
    </div>
  );
};

export default StaffDashboard;
