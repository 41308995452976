import React, { useEffect, useState } from 'react';
import API from '../../Services/api';

function CollectionPointList({ userLocation }) {
  const [collectionPoints, setCollectionPoints] = useState([]);

  useEffect(() => {
    if (userLocation) {
      const fetchCollectionPoints = async () => {
        try {
          const { data } = await API.get('/collection-points/nearby', {
            params: { longitude: userLocation[1], latitude: userLocation[0] },
          });
          setCollectionPoints(data);
        } catch (err) {
          console.error("Error fetching collection points:", err);
        }
      };
      fetchCollectionPoints();
    }
  }, [userLocation]);

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Nearby Collection Points</h2>
      {collectionPoints.length > 0 ? (
        <ul>
          {collectionPoints.map((point) => (
            <li key={point._id} className="border-b py-2">
              <strong>{point.name}</strong>
              <p>{point.address}</p>
              <p>Accepted items:</p>
              <ul className="ml-4">
                {point.acceptedItems.map((item, index) => (
                  <li key={index}>
                    <span>{item.name}</span> {/*- <span>{item.points} points</span>*/}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      ) : (
        <p>No collection points found nearby.</p>
      )}
    </div>
  );
}

export default CollectionPointList;