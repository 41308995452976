import React, {useState, useEffect} from "react";
import CountUpWrapper from "../../CountUpWrapper";

const DashboardSummary = ({user}) => {
  const [stats, setStats] = useState({
    points: 0,
    // environmentalImpact: 0,
  });

  // Function to calculate stats based on selected days
  useEffect(() => {
    if (!user || !user.recyclingHistory) return;

    const totalPoints = user.recyclingHistory.reduce((acc, entry) => {
      return acc + entry.pointsEarned;
    }, 0);
    console.log("Points: ", totalPoints);
    // const totalPoints = filteredHistory.reduce((sum, entry) => sum + entry.pointsEarned, 0);
    // const totalDevicesRecycled = filteredHistory.reduce((sum) => sum + 1, 0);
    // const totalImpact = filteredHistory.reduce((sum, entry) => sum + entry.environmentalImpact, 0);

    setStats({
      points: totalPoints,
      // devicesRecycled: totalDevicesRecycled,
      // environmentalImpact: totalImpact,
    });
  }, [user]);
  const isNewUser = !user?.recyclingHistory?.length;
  return (
    <div className="grid font-Inter grid-cols-1 lg:grid-cols-3 gap-4 px-4 md:px-8 mx-auto my-auto lg:space-x-2">
      {/* Total Points Earned */}
      <div className="bg-[#FFFFFF] w-full max-w-xs md:max-w-sm pt-4 pb-2 rounded-3xl shadow-md flex flex-col items-center justify-start relative overflow-hidden mx-auto"  data-aos="fade-left" data-aos-delay="200">
        <h3 className="text-sm  text-[#29752B] mb-2 py-1"data-aos="fade-in" data-aos-delay="300">TOTAL POINTS EARNED</h3>
        <div className="w-full h-px bg-[#BDBDBD] md-2 md:mb-4"></div>
        <span className="text-[80px] md:text-[120px] md:mx-2 md:mt-6 text-[#172A3A] md:scale-y-[1] md:scale-x-[.75]" style={{ transformOrigin: 'center' }} > <CountUpWrapper start={0} end={stats.points} duration={5} redraw={true} /><span className="text-[30px] md:text-[60px]">pts</span></span>
      </div>

            {/* Profile Impact */}
            <div
                className="bg-[#FFFFFF] w-full max-w-xs md:max-w-sm pt-4 pb-2 rounded-3xl shadow-md flex flex-col items-center justify-start relative overflow-hidden mx-auto"
                data-aos="fade-left"
                data-aos-delay="300"
              >
                <div className="flex items-center justify-between w-full px-4 py-1">
                  <h3
                    className="text-sm text-[#29752B] mb-2"
                    data-aos="fade-in"
                    data-aos-delay="400"
                  >
                    PROFILE IMPACT
                  </h3>
                  <div
                    className="bg-[#FEC731] text-[#FFFFFF] text-sm mb-1 px-2 rounded-[999px]"
                    data-aos="fade-left"
                    data-aos-delay="500"
                  >
                    i
                  </div>
                </div>
                <div className="w-full h-px bg-[#BDBDBD] mb-4"></div>

                {isNewUser ? (
                  <div className="flex flex-col items-center text-center">
                    <p className="text-[#6C757D] italic text-sm mb-4">
                      Start recycling your old devices to see your impact and earn
                      rewards. Once you add your first item, we’ll show a breakdown of
                      your recycling stats here. Ready to make a difference?
                    </p>
                    <button
                      className="flex items-center bg-[#398F3E] text-white px-4 py-2 rounded-md hover:bg-[#206A29]"
                      data-aos="fade-left"
                      data-aos-delay="500"
                      onClick={()=>{window.location.href = "/map"}}
                    >
                      <img
                        src="/images/plug_icon.png"
                        alt="Plug icon"
                        className="w-4 h-4 mr-2"
                      />
                      Recycle Now
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <div
                      className="w-32 h-32 relative md:mr-16 md:mb-2"
                      data-aos="fade-right"
                      data-aos-delay="600"
                    >
                      <div className="absolute inset-0 rounded-full bg-[#236823]"></div>
                      <div
                        className="absolute inset-0 rounded-full bg-[#4BA86D]"
                        style={{ clipPath: "polygon(100% 0, 0 0, 100% 100%)" }}
                      ></div>
                      <div
                        className="absolute inset-0 rounded-full bg-[#59CBAC]"
                        style={{ clipPath: "polygon(100% 0, 0 100%, 100% 100%)" }}
                      ></div>
                    </div>
                    <div className="space-y-2 md:ml-16 justify-content-end">
                      <div
                        className="flex items-center"
                        data-aos="fade-in"
                        data-aos-delay="600"
                      >
                        <span className="w-4 h-2 bg-[#236823] mr-2"></span>
                        <span
                          className="text-[10px] text-[#414042]"
                          data-aos="fade-in"
                          data-aos-delay="700"
                        >
                          Phones
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span
                          className="w-4 h-2 bg-[#4BA86D] mr-2"
                          data-aos="fade-in"
                          data-aos-delay="800"
                        ></span>
                        <span
                          className="text-[10px] text-[#414042]"
                          data-aos="fade-in"
                          data-aos-delay="900"
                        >
                          Laptops
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span
                          className="w-4 h-2 bg-[#59CBAC] mr-2"
                          data-aos="fade-in"
                          data-aos-delay="1000"
                        ></span>
                        <span
                          className="text-[10px] text-[#414042]"
                          data-aos="fade-in"
                          data-aos-delay="1100"
                        >
                          Tablets
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>

      {/* Point Estimate Calculator */}
      <div className="bg-[#FFFFFF] w-full max-w-xs md:max-w-sm pt-4 pb-2 rounded-3xl shadow-md flex flex-col items-start justify-start mx-auto"  data-aos="fade-left" data-aos-delay="400">
        <div>
          <h3 className="text-sm  text-[#29752B] px-2"  data-aos="fade-in" data-aos-delay="500">REWARD POINT ESTIMATE CALCULATOR</h3>
          <p className="text-[#414042] text-[10px] mb-1 px-2" data-aos="fade-in" data-aos-delay="600">See how much points your old devices are worth for recycling.</p>
          <div className="w-full h-px bg-[#BDBDBD] mb-2"></div>
        </div>
        <div className="px-5 mx-auto items-center space-y-1">
          <div data-aos="fade-in " data-aos-delay="700">
            <label htmlFor="deviceType" className="block text-[#414042] text-[10px] font-medium">
              Select Device Type
            </label>
            <select
              id="deviceType"
              className="w-full border border-gray-300 px-2 py-1 rounded-md focus:outline-none focus:ring-1 focus:ring-[#29752B] text-[10px]"
              >
              <option value="">Select</option>
              <option value="phone">Phone</option>
              <option value="laptop">Laptop</option>
            </select>
          </div>
          <div data-aos="fade-in" data-aos-delay="800">
            <label htmlFor="deviceCondition" className="block text-[#414042] text-[10px] font-medium">
              Select Device Condition
            </label>
            <select
              id="deviceCondition"
              className="w-full border border-gray-300 px-2 py-1 rounded-md focus:outline-none focus:ring-1 focus:ring-[#29752B] text-[10px]"
              >
              <option value="">Select</option>
              <option value="new">New</option>
              <option value="used">Used</option>
            </select>
          </div>
          <div data-aos="fade-in" data-aos-delay="900">
            <label htmlFor="deviceBrand" className="block text-[#414042] text-[10px] font-medium">
              Device Brand/Model
            </label>
            <input
              type="text"
              id="deviceBrand"
              className="w-full border border-gray-300 px-2 py-1 rounded-md focus:outline-none focus:ring-1 focus:ring-[#29752B] text-[10px]"
              />
          </div>
        </div>
        <div className="mt-2 border-t border-gray-200 px-2">
          <p className="text-[#414042] text-[10px]" data-aos="fade-in" data-aos-delay="1000">
            Your estimated reward point: <span className="text-[14px]  text-[#18421B]" data-aos="fade-in" data-aos-delay="800"><CountUpWrapper start={0} end={40} duration={2} redraw={true} /></span>
          </p>
          <p className="text-[#FF6666] text-[8px]" data-aos="fade-in" data-aos-delay="1000">
            *Estimate may vary based on device condition and quantity. Final offer provided by collection center.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DashboardSummary;
