import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";


const Benefits = () => {
  const [selectedBenefit, setSelectedBenefit] = useState(null);

  const benefits = [
    {
      id: 1,
      title: "Health Benefits",
      description:
        "Reduced exposure to toxic chemicals like lead and mercury, minimizing health risks. Recycling e-waste ensures safe disposal, reducing health hazards.",
      icon: "/images/icon_health_benefits.png",
      bgColor: "#172A3A",
      textColor: "#FFFFFF",
    },
    {
      id: 2,
      title: "Valuable Rewards",
      description:
        "Earn valuable rewards and contribute to a sustainable future by recycling e-waste responsibly.",
      icon: "/images/Money.png",
      bgColor: "#FFC43D",
      textColor: "#000000",
    },
    {
      id: 3,
      title: "Resource Saving",
      description:
        "Precious metals and materials can be reused. Recycling helps conserve natural resources and reduces the need for raw material extraction.",
      icon: "/images/Resource_save.png",
      bgColor: "#134611",
      textColor: "#FFFFFF",
    },
  ];

  const handleClosePopup = () => setSelectedBenefit(null);

  return (
    <section className="py-20 px-6 md:px-8 bg-[#F8FAFB] overflow-hidden" id="benefits">
      <div className="container mx-auto">
        {/* Heading */}
        <div className="mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-left ml-4 md:ml-0" data-aos="fade-right" data-aos-delay="200">
            Why Recycle <span className="text-[#51B99D]">E-Waste?</span>
          </h2>
        </div>

        
        {/* Benefit Cards */}
        <div className="flex flex-col md:flex-row md:space-x-8">
          {benefits.map((benefit) => (
            <div
              key={benefit.id}
              className="w-full md:w-1/3 p-8 rounded-[2rem] mb-8 md:mb-0 flex flex-col items-start"
              style={{ backgroundColor: benefit.bgColor }}
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img
                src={benefit.icon}
                alt={`${benefit.title} Icon`}
                className="w-[106px] h-[88px] mb-4"
              />
              <h3
                className="text-2xl font-semibold mb-4"
                style={{ color: benefit.textColor }}
              >
                {benefit.title}
              </h3>
              <p
                className="text-left text-xl"
                style={{ color: benefit.textColor }}
              >
                {benefit.description.substring(0, 70)}...
              </p>
              <br />
              <div className="mt-auto">
                <button
                  onClick={() => setSelectedBenefit(benefit)}
                  className={benefit.id === 2 ? `w-full md:w-auto py-2 px-4 pr-3 bg-[#000000] text-[#FFC43D] rounded-full border-[${benefit.bgColor}]  font-semibold flex items-center justify-center relative hover:bg-[#FFC43D] hover:text-black`:`w-full md:w-auto py-2 px-4 pr-3 bg-[${benefit.textColor}] text-[${benefit.bgColor}] rounded-full border-[${benefit.bgColor}]  font-semibold flex items-center justify-center relative hover:bg-[${benefit.textColor}]`}
                >
                  <span className="mr-3">Read More</span>
                  <div
                    className={`p-3 ml-3 rounded-full hover:text-[${benefit.bgColor}] hover:bg-[${benefit.textColor}]`}
                    style={{
                      backgroundColor: benefit.bgColor,
                      color: benefit.textColor,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="-rotate-45 w-5 h-5"
                    />
                  </div>
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Popup */}
        {selectedBenefit && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div
              className="w-11/12 md:w-2/3 lg:w-1/2 p-8 rounded-2xl relative"
              style={{ backgroundColor: selectedBenefit.bgColor }}
            >
              <button
                onClick={handleClosePopup}
                className="absolute top-4 right-4 text-white hover:text-gray-300"
              >
                <button
                className="py-2 px-6 rounded-full font-semibold"
                style={{
                  backgroundColor: selectedBenefit.textColor,
                  color: selectedBenefit.bgColor,
                }}
                onClick={() => setSelectedBenefit(null)}
              >
                Close
              </button>
              </button>
              <img
                src={selectedBenefit.icon}
                alt={`${selectedBenefit.title} Icon`}
                className="w-[106px] h-[88px] mb-4"
              />
              <h3
                className="text-3xl font-semibold mb-4"
                style={{ color: selectedBenefit.textColor }}
              >
                {selectedBenefit.title}
              </h3>
              <p
                className="text-lg"
                style={{ color: selectedBenefit.textColor }}
              >
                {selectedBenefit.description}
              </p>
            </div>
          </div>
        )}

        {/* Recycle Responsibly Section */}
        <div className="mt-16 px-4 py-8 md:px-6 md:py-12"> 
          <div className="flex flex-col md:flex-row md:space-x-10">
            {/* Left Side */}
            <div className="w-full md:w-1/2 mb-8 md:mb-0 md:pl-16" data-aos="fade-right" data-aos-delay="200">
              <div className="space-y-8 md:space-y-12"> {/* Adjusted spacing for smaller screens */}
                <h3 className="text-3xl tracking-wider md:text-4xl font-bold text-left"  > {/* Adjusted heading size */}
                  Recycle Responsibly <br /><span className="text-[#51B99D]">& Earn Rewards</span>
                </h3>
                <p className="text-gray-700 mt-4 text-lg tracking-wider"> {/* Adjusted text size */}
                  Did you know your old electronics can be recycled—and you could get money for it? 
                  Disposing of e-waste responsibly not only helps the environment but also puts cash 
                  in your pocket!
                </p>
                <p className="text-gray-700 mt-4 text-lg tracking-wider"> {/* Adjusted text size */}
                  Recycling reduces pollution, saves valuable resources, and some centres offer rewards 
                  for your old devices. Whether it's an old phone, laptop, or charger, you can turn 
                  your tech trash into treasure.
                </p>
              </div>
            </div>

            {/* Right Side */}
            <div className="w-full md:w-1/2 relative" data-aos="fade-left" data-aos-delay="300"> 
            <img
                src="/images/Mask_group.png"
                alt="Electronics Close Up"
                className="relative w-full md:w-[462px] md:h-[601px] h-auto rounded-2xl md:ml-8 z-10" 
            />
            <img
                src="/images/frame_101.png"
                alt="Electronics Background"
                className="absolute -top-10 left-24 md:left-auto md:-top-14 md:right-20 md:w-[238px] md:h-[226px] rounded-xl" 
            />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;