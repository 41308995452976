// src/pages/RewardsPage.js
import { useEffect, useState } from 'react';
import RewardList from '../Components/Rewards/RewardList';
import AddReward from '../Components/Rewards/AddReward';

function RewardsPage() {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const role = localStorage.getItem('role'); // Assuming role is stored in localStorage
    if (role === 'admin') setIsAdmin(true);
  }, []);

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Rewards</h1>
      <RewardList />
      {isAdmin && <AddReward />}
    </div>
  );
}

export default RewardsPage;
