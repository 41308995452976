import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './Components/Auth/Login';
import Register from './Components/Auth/Register';
import Profile from './Components/Profile';
import RewardsPage from './Pages/Reward';
import CollectionPointsPage from './Pages/CollectionPointPage';
import RegisterStaff from './Components/CollectionStaff/RegisterStaff';
import LoginStaff from './Components/CollectionStaff/LoginStaff';
import StaffDashboard from './Components/CollectionStaff/StaffDashboard';
import LandingPage from './Pages/LandingPage';
import Esave from './Components/Esave';
import FullPageMap from './Components/FullPageMap';



function App() {
  // State for checking token presence
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [isAuthenticatedStaff, setIsAuthenticatedStaff] = useState(!!localStorage.getItem('staff-token'));

  useEffect(() => {
    // Log authentication status
    console.log("User Authenticated:", isAuthenticated);
    console.log("Staff Authenticated:", isAuthenticatedStaff);

    // Recheck token presence if it may be set asynchronously
    setIsAuthenticated(!!localStorage.getItem('token'));
    setIsAuthenticatedStaff(!!localStorage.getItem('staff-token'));
  }, []); // Runs once on component mount

  return (
    <div className="App">
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/rewards" element={<RewardsPage />} />
        <Route path="/collection-points" element={<CollectionPointsPage />} />
        <Route path="/staff-login" element={<LoginStaff />} />
        <Route path="/staff-register" element={<RegisterStaff />} />
        <Route path="/test" element={<Esave />} />
        <Route path='/map' element={<FullPageMap />} />
        
        {/* Protected Route for Staff Dashboard */}
        <Route 
          path="/staff-dashboard" 
          element={isAuthenticatedStaff ? <StaffDashboard /> : <Navigate to="/staff-login" />}
        />
        <Route 
          path="/" 
          element={<LandingPage />} 
        />
        {/* Protected Route for Profile */}
        <Route 
          path="/profile" 
          element={isAuthenticated ? <Profile /> : <Navigate to="/login" />} 
        />

        {/* Redirect to login by default */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
}

export default App;