// src/components/Profile.js
import React from 'react';

function Esave() {
  return (
<div className="ESave w-auto h-auto relative bg-[#f8fafb]">
  {/* <div className="Rectangle232 w-[1440px] h-[638px] left-0 top-0 absolute bg-[#185140]" />
  <div className="DidYouKnowYourOldElectronicsCanBeRecycledAndYouCouldGetMoneyForItDisposingOfEWasteResponsiblyNotOnlyHelpsTheEnvironmentButAlsoPutsCashInYourPocketRecyclingReducesPollutionSavesValuableResourcesAndSomeCentresOfferRewardsForYourOldDevicesWhetherItSAnOldPhoneLaptopOrChargerYouCanTurnYourTechTrashIntoTreasure w-[519px] h-[425px] left-[165px] top-[3488px] absolute text-black text-2xl font-normal font-['Montserrat'] leading-[43.20px]">Did you know your old electronics can be recycled—and you could get money for it? Disposing of e-waste responsibly not only helps the environment but also puts cash in your pocket! <br/><br/>Recycling reduces pollution, saves valuable resources, and some centres offer rewards for your old devices. Whether it's an old phone, laptop, or charger, you can turn your tech trash into treasure.</div>
  <div className="WeVeMadeRecyclingEasyAndRewardingUseOurInteractiveEWasteMapToFindTheNearestRecyclingCentreThatAcceptsYourDevicesAndMightEvenOfferCashOrStoreCreditSimplyEnterYourLocationAndWeLlGuideYouToTheClosestDropOffPoint w-[1108px] h-32 left-[158px] top-[4665px] absolute text-black text-2xl font-normal font-['Montserrat'] leading-[43.20px]">We’ve made recycling easy and rewarding! Use our Interactive E-Waste Map to find the nearest recycling centre that accepts your devices—and might even offer cash or store credit. Simply enter your location, and we’ll guide you to the closest drop-off point.</div>
  <div className="Frame40176 h-[226px] left-[1052px] top-[3330px] absolute flex-col justify-start items-start gap-3 inline-flex">
    <div className="Frame40174 self-stretch p-[5px] justify-start items-center gap-6 inline-flex">
      <div className="Rectangle235 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle236 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle237 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle238 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle239 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle240 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle241 w-3 h-3 bg-[#d9d9d9]" />
    </div>
    <div className="Frame40175 self-stretch p-[5px] justify-start items-center gap-6 inline-flex">
      <div className="Rectangle235 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle236 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle237 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle238 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle239 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle240 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle241 w-3 h-3 bg-[#d9d9d9]" />
    </div>
    <div className="Frame40176 self-stretch p-[5px] justify-start items-center gap-6 inline-flex">
      <div className="Rectangle235 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle236 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle237 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle238 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle239 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle240 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle241 w-3 h-3 bg-[#d9d9d9]" />
    </div>
    <div className="Frame40177 self-stretch p-[5px] justify-start items-center gap-6 inline-flex">
      <div className="Rectangle235 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle236 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle237 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle238 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle239 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle240 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle241 w-3 h-3 bg-[#d9d9d9]" />
    </div>
    <div className="Frame40178 self-stretch p-[5px] justify-start items-center gap-6 inline-flex">
      <div className="Rectangle235 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle236 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle237 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle238 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle239 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle240 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle241 w-3 h-3 bg-[#d9d9d9]" />
    </div>
    <div className="Frame40179 self-stretch p-[5px] justify-start items-center gap-6 inline-flex">
      <div className="Rectangle235 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle236 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle237 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle238 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle239 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle240 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle241 w-3 h-3 bg-[#d9d9d9]" />
    </div>
    <div className="Frame40180 self-stretch p-[5px] justify-start items-center gap-6 inline-flex">
      <div className="Rectangle235 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle236 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle237 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle238 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle239 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle240 w-3 h-3 bg-[#d9d9d9]" />
      <div className="Rectangle241 w-3 h-3 bg-[#d9d9d9]" />
    </div>
  </div>
  <div className="WhyRecycleEWaste left-[165px] top-[2675px] absolute"><span className="text-black text-[40px] font-semibold font-['Montserrat'] leading-[56px]">Why Recycle </span><span className="text-[#51b99d] text-[40px] font-semibold font-['Montserrat'] leading-[56px]">E-Waste?</span></div>
  <div className="FindACollectionPointGetRewarded left-[159px] top-[4160px] absolute"><span className="text-black text-[40px] font-bold font-['Montserrat'] leading-[56px]">Find a Collection Point </span><span className="text-[#51b99d] text-[40px] font-bold font-['Montserrat'] leading-[56px]">& Get Rewarded</span></div>
  <div className="ReadyToRecycleEarnFindACentreNearYouNow left-[159px] top-[4813px] absolute text-black text-2xl font-semibold font-['Montserrat'] leading-[33.60px]">Ready to Recycle & Earn? Find a centre near you now:</div>
  <div className="RecycleResponsiblyEarnRewards left-[172px] top-[3353px] absolute"><span className="text-black text-[40px] font-bold font-['Montserrat'] leading-[56px]">Recycle Responsibly<br/></span><span className="text-[#51b99d] text-[40px] font-bold font-['Montserrat'] leading-[56px]">& Earn Rewards</span></div>
  <div className="Button w-[298px] pl-6 pr-3 py-3 left-[114px] top-[420px] absolute bg-[#59cbac] rounded-[120px] justify-between items-center inline-flex">
    <div className="ExploreMap text-center text-[#263d42] text-[28px] font-medium font-['Montserrat'] leading-[39.20px]">Explore Map</div>
    <div className="Group66 w-[52px] h-[52px] relative">
      <div className="Ellipse49 w-[52px] h-[52px] left-0 top-0 absolute bg-white rounded-full" />
      <div className="Arrow w-8 h-8 pl-[5.33px] pr-[5.32px] py-[2.67px] left-[27px] top-[3px] absolute origin-top-left rotate-[49.21deg] justify-center items-center inline-flex" />
    </div>
  </div>
  <div className="Button w-[298px] pl-6 pr-3 py-3 left-[679px] top-[1299px] absolute bg-white rounded-[120px] border-2 border-[#294437] justify-between items-center inline-flex">
    <div className="ExploreMap text-center text-[#263d42] text-[28px] font-medium font-['Montserrat'] leading-[39.20px]">Get Started</div>
    <div className="Group66 w-[52px] h-[52px] relative">
      <div className="Ellipse49 w-[52px] h-[52px] left-0 top-0 absolute bg-[#294437] rounded-full" />
      <div className="Arrow w-8 h-8 pl-[5.33px] pr-[5.32px] py-[2.67px] left-[27px] top-[3px] absolute origin-top-left rotate-[49.21deg] justify-center items-center inline-flex" />
    </div>
  </div>
  <div className="CreativeWaysToRepurposeOldOrUsedElectronicsAndStill w-[363px] h-[117px] left-[114px] top-[241px] absolute text-[#d0ddd0] text-2xl font-medium font-['Montserrat'] leading-[33.60px]">creative ways to repurpose old or used electronics and still</div>
  <div className="GetPaid left-[176px] top-[314px] absolute text-[#59cbac] text-[40px] font-bold font-['Montserrat'] leading-[56px]">get paid</div>
  <div className="Discover left-[111px] top-[189px] absolute text-[#fdc731] text-[40px] font-bold font-['Montserrat'] leading-[56px]">Discover</div>
  <img className="ElectronicsWasteBinConceptGarbageElectricalWasteReadyRecyclingOldDevicesEWasteDi92619940967991 w-[305px] h-[538px] left-[544px] top-[99px] absolute" src="https://via.placeholder.com/305x538" />
  <div className="BeforeAfterSlider w-[537.60px] h-[537.60px] left-[871.40px] top-[99.40px] absolute bg-white">
    <div className="MaskGroup w-[537.80px] h-[537.80px] left-0 top-0 absolute">
      <img className="DallE20241008152753AModernAndStylishLivingRoomSimilarToThePreviousOneFeaturingBeigeWallsRedFurnitureAndWoodenFlooringTheRoomHasATableAndChaiPhotoroom1 w-[537.60px] h-[537.60px] left-[0.20px] top-[0.20px] absolute" src="https://via.placeholder.com/538x538" />
      <div className="Rectangle243 w-[268.80px] h-[537.60px] left-0 top-0 absolute bg-[#d9d9d9]" />
      <img className="Image44 w-[537.60px] h-[537.60px] left-[0.20px] top-[0.20px] absolute" src="https://via.placeholder.com/538x538" />
    </div>
    <div className="Group60 w-[16.80px] h-[537.60px] left-[260.60px] top-0 absolute">
      <div className="Line19 w-[537.60px] h-[0px] left-[8.40px] top-0 absolute origin-top-left rotate-90 border-2 border-white"></div>
      <div className="Ellipse46 w-[16.80px] h-[16.80px] left-0 top-[260.60px] absolute bg-white rounded-full" />
    </div>
  </div>
  <div className="MaskGroup w-[1073px] h-[369px] left-[158px] top-[4266px] absolute">
    <div className="Rectangle245 w-[1073px] h-[369px] left-0 top-0 absolute bg-[#d9d9d9] rounded-2xl" />
    <img className="BasemapImage w-[1354px] h-[758px] left-[-5px] top-[-179px] absolute rounded-2xl" src="https://via.placeholder.com/1354x758" />
    <img className="Untitled1x10s200px200px3 w-10 h-10 left-[727.52px] top-[164.52px] absolute" src="https://via.placeholder.com/40x40" />
    <img className="Untitled1x10s200px200px4 w-10 h-10 left-[347px] top-[278px] absolute" src="https://via.placeholder.com/40x40" />
  </div>
  <img className="Dgifmaker257321 w-[55.50px] h-[55.50px] left-[1050px] top-[4427px] absolute" src="https://via.placeholder.com/55x55" />
  <img className="Dgifmaker257322 w-[55.50px] h-[55.50px] left-[241px] top-[4542px] absolute" src="https://via.placeholder.com/55x55" />
  <img className="Dgifmaker257323 w-[55.50px] h-[55.50px] left-[428px] top-[4342px] absolute" src="https://via.placeholder.com/55x55" />
  <div className="Steps w-[1440px] h-[1076px] left-0 top-[1515px] absolute bg-white shadow">
    <div className="Frame40185 left-[235px] top-[199px] absolute flex-col justify-start items-start gap-3 inline-flex">
      <div className="LocateACollectionCenter self-stretch text-black text-[28px] font-semibold font-['Montserrat'] leading-[39.20px]">Locate a Collection Centre</div>
      <div className="UseOurInteractiveEWasteMapToFindTheNearestRecyclingCenterOrDropOffPointInYourArea w-[285px]"><span className="text-black text-lg font-normal font-['Montserrat'] leading-loose">Use our</span><span className="text-black text-lg font-semibold font-['Montserrat'] leading-loose"> Interactive E-Waste Map</span><span className="text-black text-lg font-normal font-['Montserrat'] leading-loose"> to find the nearest recycling center or drop-off point in your area.</span></div>
    </div>
    <div className="Frame40186 left-[694px] top-[430px] absolute flex-col justify-start items-start gap-3 inline-flex">
      <div className="LocateACollectionCenter self-stretch text-black text-[28px] font-semibold font-['Montserrat'] leading-[39.20px]">Drop Off Your Old Devices</div>
      <div className="UseOurInteractiveEWasteMapToFindTheNearestRecyclingCenterOrDropOffPointInYourArea w-[285px] text-black text-lg font-normal font-['Montserrat'] leading-loose">Bring in your old electronics—phones, laptops, chargers, or any other unwanted gadgets—to the selected center.</div>
    </div>
    <div className="Frame40187 left-[278px] top-[705px] absolute flex-col justify-start items-start gap-3 inline-flex">
      <div className="LocateACollectionCenter self-stretch text-black text-[28px] font-semibold font-['Montserrat'] leading-[39.20px]">Get Paid  or Receive Rewards</div>
      <div className="UseOurInteractiveEWasteMapToFindTheNearestRecyclingCenterOrDropOffPointInYourArea w-[285px]"><span className="text-black text-lg font-normal font-['Montserrat'] leading-loose">Collection centers offer</span><span className="text-black text-lg font-semibold font-['Montserrat'] leading-loose"> cash or store credit</span><span className="text-black text-lg font-normal font-['Montserrat'] leading-loose"> for your items. Do your part for the planet and enjoy the rewards!</span></div>
    </div>
    <div className="Line20 w-[779px] h-[0px] left-[620px] top-[194px] absolute origin-top-left rotate-90 border-4 border-[#59cbac]"></div>
    <div className="Group61 w-[46px] h-[46px] left-[600px] top-[199px] absolute">
      <div className="Ellipse48 w-[46px] h-[46px] left-0 top-0 absolute bg-[#eefaf7] rounded-full border border-[#255548]" />
      <div className=" left-[19px] top-[6px] absolute text-center text-[#255548] text-2xl font-normal font-['Aladin'] leading-[33.60px]">1</div>
    </div>
    <div className="Group62 w-[46px] h-[46px] left-[600px] top-[430px] absolute">
      <div className="Ellipse48 w-[46px] h-[46px] left-0 top-0 absolute bg-[#eefaf7] rounded-full border border-[#255548]" />
      <div className=" left-[16px] top-[6px] absolute text-center text-[#255548] text-2xl font-normal font-['Aladin'] leading-[33.60px]">2</div>
    </div>
    <div className="Group63 w-[46px] h-[46px] left-[600px] top-[705px] absolute">
      <div className="Ellipse48 w-[46px] h-[46px] left-0 top-0 absolute bg-[#eefaf7] rounded-full border border-[#255548]" />
      <div className=" left-[16px] top-[6px] absolute text-center text-[#255548] text-2xl font-normal font-['Aladin'] leading-[33.60px]">3</div>
    </div>
    <div className="52169311 w-[375px] h-[375px] left-[665px] top-[42px] absolute">
      <div className="Group w-[349.24px] h-[306.09px] left-[16.76px] top-[28.75px] absolute">
      </div>
      <div className="Plants w-[348.80px] h-[165.36px] left-[11.25px] top-[125.51px] absolute">
        <div className="Group w-[103.69px] h-[117.80px] left-[245.11px] top-0 absolute">
        </div>
        <div className="Group w-[56.79px] h-[49.07px] left-[267.74px] top-[116.29px] absolute">
        </div>
        <div className="Group w-[103.69px] h-[117.80px] left-0 top-0 absolute">
        </div>
        <div className="Group w-[56.79px] h-[49.07px] left-[24.27px] top-[116.29px] absolute">
        </div>
      </div>
      <div className="Group w-[189.26px] h-[189.26px] left-[91.79px] top-[35.16px] absolute">
        <div className="Group w-[82.49px] h-[134.09px] left-[8.71px] top-[46.41px] absolute">
        </div>
        <div className="Group w-[152.11px] h-[175.09px] left-[32.90px] top-[3.85px] absolute">
        </div>
      </div>
      <div className="Character w-[84.16px] h-[227.22px] left-[161.45px] top-[114.22px] absolute">
        <div className="Group w-[22.09px] h-[14.62px] left-[55.64px] top-[212.38px] absolute">
        </div>
        <div className="Group w-[29.25px] h-4 left-[10.65px] top-[211.24px] absolute">
          <div className="Group w-[29.25px] h-4 left-0 top-0 absolute">
          </div>
        </div>
        <div className="Group w-[34.71px] h-[36.87px] left-[46.55px] top-[43.29px] absolute">
        </div>
        <div className="Group w-3.5 h-[28.84px] left-[36.56px] top-[72.71px] absolute">
        </div>
        <div className="Group w-[32.53px] h-[18.86px] left-[51.63px] top-[69.66px] absolute">
        </div>
        <div className="Group w-[19.58px] h-[30.08px] left-[8.39px] top-[89.69px] absolute">
        </div>
        <div className="Group w-[42.91px] h-[47.59px] left-0 top-0 absolute">
          <div className="Group w-[31.68px] h-[37.98px] left-[11.23px] top-[9.62px] absolute">
          </div>
        </div>
      </div>
      <div className="Group w-[73.21px] h-[101.29px] left-[227.52px] top-[240.14px] absolute">
        <div className="Group w-[73.21px] h-[101.29px] left-0 top-0 absolute">
        </div>
        <div className="Group w-[35.15px] h-[35.05px] left-[18.98px] top-[19.31px] absolute">
        </div>
      </div>
      <div className="Group w-[73.21px] h-[101.29px] left-[149.90px] top-[240.14px] absolute">
        <div className="Group w-[73.21px] h-[101.29px] left-0 top-0 absolute">
        </div>
        <div className="Group w-[35.15px] h-[35.05px] left-[18.98px] top-[19.31px] absolute">
        </div>
      </div>
      <div className="Group w-[73.21px] h-[101.29px] left-[70.80px] top-[240.14px] absolute">
        <div className="Group w-[73.21px] h-[101.29px] left-0 top-0 absolute">
        </div>
        <div className="Group w-[35.15px] h-[35.05px] left-[18.98px] top-[19.31px] absolute">
        </div>
      </div>
    </div>
    <div className="RecycleSignSvgrepoCom1 w-[287px] h-[287px] py-[13.72px] left-[1079px] top-[789px] absolute opacity-30 justify-center items-center inline-flex">
      <div className="Group w-[287px] h-[259.57px] relative">
      </div>
    </div>
    <div className="UndrawTreasureOf9I1 w-[341px] h-[290px] left-[670px] top-[657px] absolute">
      <div className="FinanceTarget w-[150px] h-[150px] left-[108px] top-[126px] absolute" />
    </div>
    <div className="EmptyBox w-[452px] h-[356px] left-[148px] top-[396px] absolute" />
    <div className="Electronics14SvgrepoCom1 w-[180px] h-[180px] pt-[0.35px] left-[1181px] top-[51px] absolute opacity-20 justify-center items-center inline-flex" />
    <div className="HowItWorks w-[359px] h-[53px] left-[241px] top-[69px] absolute"><span className="text-black text-[40px] font-semibold font-['Montserrat'] leading-[56px]">How it </span><span className="text-[#59cbac] text-[40px] font-semibold font-['Montserrat'] leading-[56px]">works</span></div>
    <div className="Button w-[640px] p-6 left-[400px] top-[955px] absolute bg-[#59cbac] rounded-[64px] justify-center items-center gap-2.5 inline-flex">
      <div className="Login text-center text-[#263d42] text-2xl font-semibold font-['Montserrat'] leading-[33.60px]">Explore Map</div>
    </div>
  </div>
  <div className="AboutESave w-[359px] h-[53px] left-[686px] top-[757px] absolute"><span className="text-black text-[40px] font-semibold font-['Montserrat'] leading-[56px]">About </span><span className="text-[#59cbac] text-[40px] font-semibold font-['Montserrat'] leading-[56px]">e-save</span></div>
  <div className="Group65 w-[88px] h-[110px] left-[98px] top-[1535px] absolute">
    <div className="EpArrowDownBold w-[88px] h-[88px] pl-[7.39px] pr-[7.45px] pt-[27.50px] pb-[18.39px] left-0 top-0 absolute justify-center items-center inline-flex" />
    <div className="EpArrowDownBold w-[88px] h-[88px] pl-[7.39px] pr-[7.45px] pt-[27.50px] pb-[18.39px] left-0 top-[22px] absolute justify-center items-center inline-flex" />
  </div>
  <div className="Footer h-[120px] left-0 top-[5107px] absolute bg-[#1d2838] flex-col justify-start items-start inline-flex">
    <div className="Section self-stretch h-[120px] py-12 bg-[#0f1728] flex-col justify-start items-center gap-16 flex">
      <div className="Container px-8 flex-col justify-start items-start gap-16 flex">
        <div className="Content self-stretch justify-start items-center gap-8 inline-flex">
          <div className="FooterText grow shrink basis-0 text-[#98a1b2] text-base font-normal font-['Inter'] leading-normal">© 2024 e-save. All rights reserved.</div>
          <div className="SocialIcons justify-start items-center gap-6 flex">
            <div className="SocialIcon w-6 h-6 py-[2.25px] justify-center items-center flex" />
            <div className="SocialIcon w-6 h-6 justify-center items-center flex" />
            <div className="SocialIcon w-6 h-6 pb-[0.15px] justify-center items-center flex" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="AtESaveWeBelieveInThePowerOfRepurposingOldElectronicsToReduceElectronicWasteAndImproveOurCommunitySHealth w-[714px] h-28 left-[679px] top-[869px] absolute text-black text-2xl font-normal font-['Montserrat'] leading-[38.40px]">At e-save, we believe in the power of repurposing old electronics to reduce electronic waste and improve our community's health.</div>
  <div className="OurTeamIsDedicatedToFindingInnovativeSolutionsForRecyclingElectronicDevicesAndCreatingASustainableFutureForGenerationsToCome w-[703px] h-28 left-[679px] top-[1005px] absolute text-black text-2xl font-normal font-['Montserrat'] leading-[38.40px]">Our team is dedicated to finding innovative solutions for recycling electronic devices and creating a sustainable future for generations to come.</div>
  <div className="JoinUsInOurMissionToMakeADifferenceByResponsiblyDisposingOfElectronicWasteAndContributingToAHealthierEnvironment w-[703px] h-28 left-[679px] top-[1141px] absolute text-black text-2xl font-normal font-['Montserrat'] leading-[38.40px]">Join us in our mission to make a difference by responsibly disposing of electronic waste and contributing to a healthier environment.</div>
  <div className="Rectangle247 w-[330px] h-[348px] left-[122px] top-[2832px] absolute bg-[#172a3a] rounded-[48px]" />
  <div className="Rectangle248 w-[330px] h-[348px] left-[527px] top-[2832px] absolute bg-[#ffc43d] rounded-[54px]" />
  <div className="Rectangle249 w-[330px] h-[348px] left-[942px] top-[2832px] absolute bg-[#134611] rounded-[54px]" />
  <div className="Frame40191 h-[133px] left-[149px] top-[3012px] absolute flex-col justify-start items-start gap-3 inline-flex">
    <div className="HealthBenefits w-[283px] text-[#eefaf7] text-2xl font-semibold font-['Montserrat'] leading-[33.60px]">Health Benefits</div>
    <div className="ReducedExposureToToxicChemicalsLikeLeadAndMercuryMinimizingHealthRisks w-[271px] text-[#eefaf7] text-base font-medium font-['Montserrat'] leading-7">Reduced exposure to toxic chemicals like lead and mercury, minimizing health risks.</div>
  </div>
  <div className="Frame40192 h-[104px] left-[554px] top-[3012px] absolute flex-col justify-start items-start gap-3 inline-flex">
    <div className="MonetaryBenefits w-[283px] text-[#172a3a] text-2xl font-semibold font-['Montserrat'] leading-[33.60px]">Monetary Benefits</div>
    <div className="RecyclingCentresPayYouForYourOldElectronics w-[271px] text-[#172a3a] text-base font-medium font-['Montserrat'] leading-7">Recycling centres pay you for your old electronics.</div>
  </div>
  <div className="Frame40193 h-[104px] left-[965px] top-[3012px] absolute flex-col justify-start items-start gap-3 inline-flex">
    <div className="ResourceSaving w-[283px] text-[#eefaf7] text-2xl font-semibold font-['Montserrat'] leading-[33.60px]">Resource Saving</div>
    <div className="PreciousMetalsAndMaterialsCanBeReused w-[271px] text-[#eefaf7] text-base font-medium font-['Montserrat'] leading-7">Precious metals and materials can be reused.</div>
  </div>
  <div className="Money w-24 h-24 left-[567px] top-[2882px] absolute" />
  <img className="Image46 w-[95.04px] h-[95.04px] left-[974px] top-[2878px] absolute" src="https://via.placeholder.com/95x95" />
  <div className="MaskGroup origin-top-left rotate-180 w-[462px] h-[601px] left-[1232px] top-[3378px] absolute">
    <div className="Rectangle233 w-[462px] h-[601px] left-0 top-0 absolute origin-top-left rotate-180 bg-[#d9d9d9] rounded-2xl" />
    <img className="Image48 w-[885px] h-[618px] left-[-523px] top-[-14px] absolute" src="https://via.placeholder.com/885x618" />
  </div>
  <div className="UkMap w-[626px] h-[917px] left-[12px] top-[646px] absolute" />
  <div className="MapLocator w-[446px] h-[652px] left-[88px] top-[827px] absolute">
    <div className="MapLocationMarker w-10 h-10 left-[292px] top-[355px] absolute rounded-[28px]">
      <div className="OuterCircle w-10 h-10 left-0 top-0 absolute opacity-10 bg-[#ffcc00] rounded-[28px]" />
      <div className="MiddleCircle w-6 h-6 left-[8px] top-[8px] absolute opacity-20 bg-[#ffcc00] rounded-[28px]" />
      <div className="InnerCircle w-2 h-2 left-[16px] top-[16px] absolute bg-[#ffcc00] rounded-[28px]" />
    </div>
    <div className="MapLocationMarker w-10 h-10 left-[175px] top-[187px] absolute rounded-[28px]">
      <div className="OuterCircle w-10 h-10 left-0 top-0 absolute opacity-10 bg-[#ffcc00] rounded-[28px]" />
      <div className="MiddleCircle w-6 h-6 left-[8px] top-[8px] absolute opacity-20 bg-[#ffcc00] rounded-[28px]" />
      <div className="InnerCircle w-2 h-2 left-[16px] top-[16px] absolute bg-[#ffcc00] rounded-[28px]" />
    </div>
    <div className="MapLocationMarker w-10 h-10 left-[154px] top-[40px] absolute rounded-[28px]">
      <div className="OuterCircle w-10 h-10 left-0 top-0 absolute opacity-10 bg-[#ffcc00] rounded-[28px]" />
      <div className="MiddleCircle w-6 h-6 left-[8px] top-[8px] absolute opacity-20 bg-[#ffcc00] rounded-[28px]" />
      <div className="InnerCircle w-2 h-2 left-[16px] top-[16px] absolute bg-[#ffcc00] rounded-[28px]" />
    </div>
    <div className="MapLocationMarker w-10 h-10 left-[236px] top-0 absolute rounded-[28px]">
      <div className="OuterCircle w-10 h-10 left-0 top-0 absolute opacity-10 bg-[#ffcc00] rounded-[28px]" />
      <div className="MiddleCircle w-6 h-6 left-[8px] top-[8px] absolute opacity-20 bg-[#ffcc00] rounded-[28px]" />
      <div className="InnerCircle w-2 h-2 left-[16px] top-[16px] absolute bg-[#ffcc00] rounded-[28px]" />
    </div>
    <div className="MapLocationMarker w-10 h-10 left-[220px] top-[145px] absolute rounded-[28px]">
      <div className="OuterCircle w-10 h-10 left-0 top-0 absolute opacity-10 bg-[#ffcc00] rounded-[28px]" />
      <div className="MiddleCircle w-6 h-6 left-[8px] top-[8px] absolute opacity-20 bg-[#ffcc00] rounded-[28px]" />
      <div className="InnerCircle w-2 h-2 left-[16px] top-[16px] absolute bg-[#ffcc00] rounded-[28px]" />
    </div>
    <div className="MapLocationMarker w-10 h-10 left-[164px] top-[129px] absolute rounded-[28px]">
      <div className="OuterCircle w-10 h-10 left-0 top-0 absolute opacity-10 bg-[#ffcc00] rounded-[28px]" />
      <div className="MiddleCircle w-6 h-6 left-[8px] top-[8px] absolute opacity-20 bg-[#ffcc00] rounded-[28px]" />
      <div className="InnerCircle w-2 h-2 left-[16px] top-[16px] absolute bg-[#ffcc00] rounded-[28px]" />
    </div>
    <div className="MapLocationMarker w-10 h-10 left-0 top-[244px] absolute rounded-[28px]">
      <div className="OuterCircle w-10 h-10 left-0 top-0 absolute opacity-10 bg-[#ffcc00] rounded-[28px]" />
      <div className="MiddleCircle w-6 h-6 left-[8px] top-[8px] absolute opacity-20 bg-[#ffcc00] rounded-[28px]" />
      <div className="InnerCircle w-2 h-2 left-[16px] top-[16px] absolute bg-[#ffcc00] rounded-[28px]" />
    </div>
    <div className="MapLocationMarker w-10 h-10 left-[292px] top-[224px] absolute rounded-[28px]">
      <div className="OuterCircle w-10 h-10 left-0 top-0 absolute opacity-10 bg-[#ffcc00] rounded-[28px]" />
      <div className="MiddleCircle w-6 h-6 left-[8px] top-[8px] absolute opacity-20 bg-[#ffcc00] rounded-[28px]" />
      <div className="InnerCircle w-2 h-2 left-[16px] top-[16px] absolute bg-[#ffcc00] rounded-[28px]" />
    </div>
    <div className="MapLocationMarker w-10 h-10 left-[268px] top-[467px] absolute rounded-[28px]">
      <div className="OuterCircle w-10 h-10 left-0 top-0 absolute opacity-10 bg-[#ffcc00] rounded-[28px]" />
      <div className="MiddleCircle w-6 h-6 left-[8px] top-[8px] absolute opacity-20 bg-[#ffcc00] rounded-[28px]" />
      <div className="InnerCircle w-2 h-2 left-[16px] top-[16px] absolute bg-[#ffcc00] rounded-[28px]" />
    </div>
    <div className="MapLocationMarker w-10 h-10 left-[175px] top-[526px] absolute rounded-[28px]">
      <div className="OuterCircle w-10 h-10 left-0 top-0 absolute opacity-10 bg-[#ffcc00] rounded-[28px]" />
      <div className="MiddleCircle w-6 h-6 left-[8px] top-[8px] absolute opacity-20 bg-[#ffcc00] rounded-[28px]" />
      <div className="InnerCircle w-2 h-2 left-[16px] top-[16px] absolute bg-[#ffcc00] rounded-[28px]" />
    </div>
    <div className="MapLocationMarker w-10 h-10 left-[406px] top-[447px] absolute rounded-[28px]">
      <div className="OuterCircle w-10 h-10 left-0 top-0 absolute opacity-10 bg-[#ffcc00] rounded-[28px]" />
      <div className="MiddleCircle w-6 h-6 left-[8px] top-[8px] absolute opacity-20 bg-[#ffcc00] rounded-[28px]" />
      <div className="InnerCircle w-2 h-2 left-[16px] top-[16px] absolute bg-[#ffcc00] rounded-[28px]" />
    </div>
    <div className="MapLocationMarker w-10 h-10 left-[268px] top-[612px] absolute rounded-[28px]">
      <div className="OuterCircle w-10 h-10 left-0 top-0 absolute opacity-10 bg-[#ffcc00] rounded-[28px]" />
      <div className="MiddleCircle w-6 h-6 left-[8px] top-[8px] absolute opacity-20 bg-[#ffcc00] rounded-[28px]" />
      <div className="InnerCircle w-2 h-2 left-[16px] top-[16px] absolute bg-[#ffcc00] rounded-[28px]" />
    </div>
    <div className="MapLocationMarker w-10 h-10 left-[353px] top-[510px] absolute rounded-[28px]">
      <div className="OuterCircle w-10 h-10 left-0 top-0 absolute opacity-10 bg-[#ffcc00] rounded-[28px]" />
      <div className="MiddleCircle w-6 h-6 left-[8px] top-[8px] absolute opacity-20 bg-[#ffcc00] rounded-[28px]" />
      <div className="InnerCircle w-2 h-2 left-[16px] top-[16px] absolute bg-[#ffcc00] rounded-[28px]" />
    </div>
    <div className="MapLocationMarker w-10 h-10 left-[402px] top-[592px] absolute rounded-[28px]">
      <div className="OuterCircle w-10 h-10 left-0 top-0 absolute opacity-10 bg-[#ffcc00] rounded-[28px]" />
      <div className="MiddleCircle w-6 h-6 left-[8px] top-[8px] absolute opacity-20 bg-[#ffcc00] rounded-[28px]" />
      <div className="InnerCircle w-2 h-2 left-[16px] top-[16px] absolute bg-[#ffcc00] rounded-[28px]" />
    </div>
  </div>
  <img className="Image49 w-[106px] h-[88px] left-[157px] top-[2882px] absolute" src="https://via.placeholder.com/106x88" />
  <div className="Frame40199 left-[149px] top-[4899px] absolute justify-start items-start inline-flex">
    <div className="Frame40198 w-[847px] h-20 px-5 py-[23px] bg-white rounded-tl-2xl rounded-bl-2xl border border-[#ebedf0] justify-start items-end gap-2.5 flex">
      <div className="EnterLocationOrCityName text-[#6e7c86] text-2xl font-normal font-['Montserrat'] leading-[33.60px]">Enter location or city name</div>
    </div>
    <div className="Button w-[232px] h-20 p-6 bg-[#59cbac] rounded-tr-2xl rounded-br-2xl justify-center items-center gap-2.5 flex">
      <div className="Search text-center text-[#263d42] text-2xl font-semibold font-['Montserrat'] leading-[33.60px]">Search</div>
    </div>
  </div>
  <div className="Header w-[1440px] h-[100px] px-10 left-0 top-0 absolute bg-[#185140] border-b border-[#d0ddd0] justify-between items-center inline-flex">
    <div className="Logo w-[195.89px] h-[42px] justify-center items-center gap-[10.17px] flex">
      <div className="Group w-[158.33px] h-[26.75px] relative">
      </div>
    </div>
    <div className="Frame40188 h-[33px] justify-center items-center gap-[30px] flex">
      <div className="Menuitem px-4 py-1.5 rounded-[100px] justify-center items-center gap-2.5 flex">
        <div className="AboutESave text-center text-[#d0ddcf] text-xl font-normal font-['Montserrat'] leading-[21px]">About e-save</div>
      </div>
      <div className="Menuitem px-4 py-1.5 rounded-[100px] justify-center items-center gap-2.5 flex">
        <div className="AboutESave text-center text-[#d0ddcf] text-xl font-normal font-['Montserrat'] leading-[21px]">How it works</div>
      </div>
      <div className="Menuitem px-4 py-1.5 rounded-[100px] justify-center items-center gap-2.5 flex">
        <div className="AboutESave text-center text-[#d0ddcf] text-xl font-normal font-['Montserrat'] leading-[21px]">Benefits</div>
      </div>
      <div className="Menuitem px-4 py-1.5 rounded-[100px] justify-center items-center gap-2.5 flex">
        <div className="AboutESave text-center text-[#d0ddcf] text-xl font-normal font-['Montserrat'] leading-[21px]">Map</div>
      </div>
    </div>
    <div className="Frame40200 justify-start items-center gap-5 flex">
      <div className="Frame40189 px-4 py-1.5 rounded-[100px] border border-[#d0ddd0] justify-center items-center gap-2.5 flex">
        <div className="Login text-center text-white text-base font-medium font-['Montserrat'] leading-[21px]">Login</div>
      </div>
      <div className="Frame40190 px-4 py-1.5 bg-[#3d3d3d] rounded-[100px] border border-[#d0ddd0] justify-center items-center gap-2.5 flex">
        <div className="Register text-center text-white text-base font-medium font-['Montserrat'] leading-[21px]">Register</div>
      </div>
    </div>
  </div> */}
  {/* this is the login */}
  <div className="Login w-full h-auto relative bg-[#f8fafb]">
  <div className="Rectangle253 w-[1280px] h-[885px] left-[80px] top-[81px] absolute bg-white rounded-lg shadow" />
  <div className="Terms left-[345px] top-[496px] absolute text-[#398f3e] text-sm font-medium font-['Inter'] leading-tight">Forgot Password?</div>
  <div className="Frame40211 p-2.5 left-[131px] top-[724px] absolute justify-center items-center gap-2.5 inline-flex">
    <div className="Terms"><span className="text-[#344053] text-sm font-medium font-['Inter'] leading-tight">Don’t have an account? </span><span className="text-[#398f3e] text-sm font-semibold font-['Inter'] leading-tight">Sign up</span></div>
  </div>
  <div className="Rectangle254 w-[741px] h-[724px] left-[574px] top-[149px] absolute bg-[#242e3e]/5 rounded-lg" />
  <div className="TextAndSupportingText h-[52px] left-[123px] top-[165px] absolute flex-col justify-start items-start gap-2 inline-flex">
    <div className="Text self-stretch text-center text-[#0f1728] text-[32px] font-bold font-['Inter'] leading-normal">Welcome back</div>
    <div className="SupportingText self-stretch text-center text-[#667084] text-sm font-normal font-['Inter'] leading-tight">Sign in to access your account</div>
  </div>
  <div className="TextAndSupportingText h-[52px] left-[769px] top-[660px] absolute flex-col justify-start items-start gap-2 inline-flex">
    <div className="Text self-stretch text-center text-[#0f1728] text-[28px] font-bold font-['Inter'] leading-normal">Recycle, Track and Earn</div>
    <div className="SupportingText self-stretch text-center text-[#667084] text-sm font-normal font-['Inter'] leading-tight">Locate nearby e-waste centres</div>
  </div>
  <div className="InputFieldBase h-[70px] left-[147px] top-[291px] absolute flex-col justify-start items-start gap-1.5 inline-flex">
    <div className="InputWithLabel self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
      <div className="Label text-[#344053] text-sm font-medium font-['Inter'] leading-tight">Email</div>
      <div className="Input self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#cfd4dc] justify-start items-center gap-2 inline-flex">
        <div className="Content grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
          <div className="Mail w-5 h-5 px-[1.67px] py-[3.33px] justify-center items-center flex" />
          <div className="Text grow shrink basis-0 text-[#667084] text-base font-normal font-['Inter'] leading-normal">olivia@mail.com</div>
        </div>
      </div>
    </div>
  </div>
  <div className="InputFieldBase h-[70px] left-[147px] top-[404px] absolute flex-col justify-start items-start gap-1.5 inline-flex">
    <div className="InputWithLabel self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
      <div className="Label text-[#344053] text-sm font-medium font-['Inter'] leading-tight">Password</div>
      <div className="Input self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#cfd4dc] justify-start items-center gap-2 inline-flex">
        <div className="Content grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
          <div className="Lock w-6 h-6 px-1 py-0.5 justify-center items-center flex" />
          <div className="Text w-[110.23px] h-[6.55px] relative">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="Frame40208 h-[138.60px] left-[131px] top-[565px] absolute flex-col justify-start items-center gap-2 inline-flex">
    <div className="ModalActions self-stretch justify-start items-start gap-3 inline-flex">
      <div className="Button grow shrink basis-0 h-12 rounded-lg justify-center items-start flex">
        <div className="ButtonBase grow shrink basis-0 h-12 px-4 py-3.5 bg-[#398f3e] rounded-lg shadow border border-[#398f3e] justify-center items-center gap-2 flex">
          <div className="Text text-white text-sm font-semibold font-['Inter'] leading-tight">Sign in</div>
        </div>
      </div>
    </div>
    <div className="Frame40207 justify-start items-center gap-5 inline-flex">
      <div className="Line25 w-[143px] h-[0px] border border-[#e8e8ea]"></div>
      <div className="Terms text-[#7b7b7b] text-sm font-medium font-['Inter'] leading-tight">or</div>
      <div className="Line26 w-[143px] h-[0px] border border-[#e8e8ea]"></div>
    </div>
    <div className="ModalActions self-stretch justify-start items-start gap-3 inline-flex">
      <div className="Button grow shrink basis-0 h-[54.60px] rounded-lg justify-center items-start flex">
        <div className="ButtonBase grow shrink basis-0 h-[54.60px] px-4 py-3.5 bg-[#f4f4f6] rounded-lg shadow border border-[#e8e8ea] justify-center items-center gap-8 flex">
          <div className="Group w-[26px] h-[26.60px] relative">
            <div className="ClipPathGroup w-[26px] h-[26.60px] left-0 top-0 absolute">
            </div>
            <div className="ClipPathGroup w-[26px] h-[26.60px] left-0 top-0 absolute">
            </div>
            <div className="ClipPathGroup w-[26px] h-[26.60px] left-0 top-0 absolute">
            </div>
            <div className="ClipPathGroup w-[26px] h-[26.60px] left-0 top-0 absolute">
            </div>
          </div>
          <div className="Text text-[#130303] text-sm font-bold font-['Inter'] leading-tight">Sign in with Google</div>
        </div>
      </div>
    </div>
  </div>
  <div className="Group2281 w-[455px] h-[302px] left-[717px] top-[291px] absolute">
    <div className="MaskGroup w-[260px] h-[260px] left-[195px] top-[42px] absolute">
      <div className="Rectangle256 w-[260px] h-[260px] left-0 top-0 absolute bg-white rounded-lg" />
      <img className="Image51 w-[305px] h-[319px] left-[-23px] top-[-15px] absolute" src="https://via.placeholder.com/305x319" />
    </div>
    <div className="MaskGroup w-[260px] h-[260px] left-0 top-0 absolute">
      <div className="Rectangle256 w-[260px] h-[260px] left-0 top-0 absolute bg-white rounded-lg" />
      <img className="Image52 w-[402px] h-[290px] left-[-106px] top-[-10px] absolute" src="https://via.placeholder.com/402x290" />
    </div>
  </div>
  <div className="Frame40209 left-[906px] top-[805px] absolute justify-start items-center gap-6 inline-flex">
    <div className="Ellipse210 w-2.5 h-2.5 bg-[#545c61] rounded-full" />
    <div className="Ellipse211 w-2.5 h-2.5 bg-[#d9d9d9] rounded-full" />
    <div className="Ellipse212 w-2.5 h-2.5 bg-[#d9d9d9] rounded-full" />
  </div>
</div>
</div>


  );
}

export default Esave;

