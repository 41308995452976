import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, Circle, CircleMarker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import API from '../../Services/api';
import { Tooltip } from "react-tooltip";


// Custom spinning recycle icon
const customIcon = L.divIcon({
  html: `<img src="/images/recycle_rotate.png" style="animation: spin 2s ease-in-out infinite; width: 45px; height: 45px;" />`,
  iconSize: [45, 45],
  iconAnchor: [22.5, 45],
  popupAnchor: [0, -45],
  className: "bg-transparent border-none",
});

// Locate user and move map
function LocationMarker({ setUserLocation }) {
  const map = useMap();

  useEffect(() => {
    map.locate().on('locationfound', (e) => {
      console.log("User location found:", e.latlng);
      setUserLocation([e.latlng.lat, e.latlng.lng]);
      map.flyTo(e.latlng, map.getZoom());
    });
  }, [map, setUserLocation]);

  return null;
}

// Custom Zoom Controls
function CustomZoomControls() {
  const map = useMap();

  useEffect(() => {
    const zoomControls = L.control({ position: 'topright' });

    zoomControls.onAdd = () => {
      const div = L.DomUtil.create('div', 'custom-zoom-controls');
      div.innerHTML = `
        <div class="fixed right-6 flex flex-col bg-white p-2 border border-[#E2E8F0] rounded-lg">
          <button class="zoom-btn zoom-in text-3xl text-[#004d3f] flex items-center justify-center hover:bg-[#F1FAF7] p-1 rounded-full mb-2">
            <i class="fas fa-plus"></i> 
          </button>
          <!-- Divider -->
          <div class="border-t border-[#004d3f] my-2"></div>
          <button class="zoom-btn zoom-out text-3xl text-[#004d3f] flex items-center justify-center hover:bg-[#F1FAF7] p-1 rounded-full">
            <i class="fas fa-minus"></i> 
          </button>
        </div>
      `;
      div.style.cursor = 'pointer';

      // Event listeners for zoom buttons
      div.querySelector('.zoom-in').onclick = () => map.zoomIn();
      div.querySelector('.zoom-out').onclick = () => map.zoomOut();

      return div;
    };

    zoomControls.addTo(map);
    return () => zoomControls.remove(); // Cleanup on unmount
  }, [map]);

  return null;
}

// Custom Attribution Control
function CustomAttribution() {
  const map = useMap();

  useEffect(() => {
    map.attributionControl.setPrefix('<a href="/" class="text-[#29752B] decoration-none hover:decoration-underline" target="_blank">e-save</a>');
  }, [map]);

  return null;
}
// Utility to navigate the map
function PanToLocation({ location }) {
  const map = useMap();

  useEffect(() => {
    if (location) {
      map.flyTo(location, map.getZoom());
    }
  }, [location, map]);

  return null;
}
// Main Map Component
function CollectionPointMap({ onMarkerClick }) {
  const [userLocation, setUserLocation] = useState([51.519697324452714, -0.11004609207697316]);
  const [collectionPoints, setCollectionPoints] = useState([]);
  const [bookmarkedLocations, setBookmarkedLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showBookmarkedList, setShowBookmarkedList] = useState(false);

  const radius = 8000; // Radius in meters
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const { data } = await API.get('/user/profile'); // Backend endpoint for fetching user profile
        console.log("Fetched user profile:", data);
        setBookmarkedLocations(data.bookmarkedLocations || []); // Update bookmarked locations
      } catch (err) {
        console.error("Error fetching user profile:", err);
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (userLocation) {
      const fetchCollectionPoints = async () => {
        try {
          const { data } = await API.get('/collection-points/nearby', {
            params: { longitude: userLocation[1], latitude: userLocation[0] },
          });
          console.log("Fetched collection points data:", data);
          setCollectionPoints(data);
        } catch (err) {
          console.error("Error fetching collection points:", err);
        }
      };
      fetchCollectionPoints();
    }
  }, [userLocation]);

  const handleSelectLocation = (location) => {
    setSelectedLocation(location);
  };
  const toggleBookmarkedList = () => {
    setShowBookmarkedList(!showBookmarkedList);
  };

  return (
    <div className='relative'>
      <MapContainer
        center={userLocation || [51.519697324452714, -0.11004609207697316]}
        zoom={11}
        className="h-[700px] w-full rounded-3xl"
        attributionControl
        zoomControl={false} // Disable default zoom controls
      >
        {/* Add custom controls */}
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <LocationMarker setUserLocation={setUserLocation} />
        <CustomZoomControls />
        <CustomAttribution />

        {/* Radius Circle */}
        {userLocation && (
          <Circle
            center={userLocation}
            radius={radius}
            pathOptions={{ color: 'blue', fillColor: 'blue', fillOpacity: 0.2 }}
          />
        )}

        {/* User's Exact Location Marker */}
        {userLocation && (
          <CircleMarker
            center={userLocation}
            radius={5}
            pathOptions={{ color: 'blue', fillColor: 'blue', fillOpacity: 1 }}
          />
        )}

        {/* Collection Points */}
        {collectionPoints.map((point) => {
          const coordinates = [...point.location.coordinates].reverse();
          return (
            <Marker key={point._id} position={coordinates} icon={customIcon} eventHandlers={{ click: () => onMarkerClick(point) }}>
              <Popup>
                <strong>{point.name}</strong>
                <br />
                {point.address}
                <br />
                <strong>Accepted Items: </strong>
                <span>
                  {point.acceptedItems.map((item, index) => (
                    <span key={index}>
                      {item.name}
                      {index < point.acceptedItems.length - 1 && ' | '}
                    </span>
                  ))}
                </span>
              </Popup>
            </Marker>
          );
        })}
        <PanToLocation location={selectedLocation} />
      </MapContainer>

      <button
        onClick={toggleBookmarkedList}
        className="fixed top-[6.4rem] left-4 bg-[#004d3f] text-white p-3 rounded-full shadow-lg z-[9999] hover:bg-green-600"
        data-tooltip-id="savedLocations"
        data-tooltip-content="Saved locations"
        data-tooltip-place='right'
      >
        <i className="fas fa-bookmark text-white"></i> {/* Save Icon */}
      </button>
      <Tooltip id="savedLocations" style={{ zIndex: 10000 }} />
      <Tooltip anchorSelect='.zoom-in' place='left' style={{ zIndex: 10000 }} >
        Zoom in
      </Tooltip>
      <Tooltip anchorSelect='.zoom-out' place='left' style={{ zIndex: 10000 }} >
        Zoom out
      </Tooltip>


      {/* Bookmarked Locations List */}
      {showBookmarkedList && bookmarkedLocations.length > 0 && (
        <div className="absolute bottom-4 left-4 bg-white p-4 rounded-lg shadow-lg max-w-sm z-[9999]">
          <h3 className="font-bold mb-2">Saved Locations</h3>
          <ul>
            {bookmarkedLocations.map((loc, index) => (
              <li
                key={index}
                onClick={() => setSelectedLocation(loc)}
                className="cursor-pointer text-blue-500 hover:underline"
              >
                Lat: {loc[0]}, Lng: {loc[1]}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CollectionPointMap;
