import React, { useState } from 'react';
import API from '../../Services/api';
import { Tooltip } from "react-tooltip";

function RecyclingSubmissionPopup({ collectionPoint, closePopup, initialData, estimatedPoints, environmentalImpact }) {
  const [items, setItems] = useState([
    {
      ...initialData,
      estimatedPoints,
      environmentalImpact
    }
  ]);

  const defaultWeights = {
    "Mobile Phone": 0.2,
    "Laptop": 2.5,
    "Monitor": 5.0,
  };

  const conditionMultipliers = {
    New: 1,
    Used: 0.7,
    Damaged: 0.4,
  };

  const calculatePoints = (item) => {
    const weight = item.weight || defaultWeights[item.itemType] || 1;
    const basePoints = collectionPoint.acceptedItems.find(i => i.name === item.itemType)?.points || 10;
    const conditionMultiplier = conditionMultipliers[item.condition] || 1;
    return Math.round(basePoints * conditionMultiplier * weight * item.quantity);
  };

  const calculateEnvironmentalImpact = (item) => {
    const weightFactor = item.weight || defaultWeights[item.itemType] || 1;
    const quantityFactor = item.quantity;
    const conditionImpact = conditionMultipliers[item.condition] || 1;

    // Environmental impact score calculation, ensuring it's a whole number between 1 and 5
    const impactScore = Math.min(5, Math.max(1, Math.round(weightFactor * quantityFactor * conditionImpact / 2)));
    return impactScore;
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;

    if (field === 'itemType' && defaultWeights[value]) {
      updatedItems[index].weight = defaultWeights[value];
    }

    updatedItems[index].estimatedPoints = calculatePoints(updatedItems[index]);
    updatedItems[index].environmentalImpact = calculateEnvironmentalImpact(updatedItems[index]);
    setItems(updatedItems);
  };

  const handleAddItem = () => {
    setItems([
      ...items,
      { itemType: '', weight: 0.5, quantity: 1, condition: 'New', brandModel: '', estimatedPoints: 0, environmentalImpact: 0 },
    ]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem('userId');

    if (!userId) {
      alert("You need to be logged in to submit a recycling request.");
      // Save the recycling request data to localStorage for later
      const recyclingRequest = {
        collectionPointId: collectionPoint._id,
        items,
      };
      localStorage.setItem("pendingRecyclingRequest", JSON.stringify(recyclingRequest));
      localStorage.setItem("requestExpiry", Date.now() + 60 * 60 * 1000); // Store expiry timestamp (1 hour)
      window.location.href = "/login"; // Redirect to login
      return;
    }

    try {
      const recyclingHistory = items.map(item => ({
        deviceType: item.itemType,
        deviceCondition: item.condition,
        brandModel: item.brandModel,
        weight: item.weight,
        itemsRecycled: item.quantity,
        pointsEarned: item.estimatedPoints,
        environmentalImpact: item.environmentalImpact,
        quantity: item.quantity,
        status: 'Pending',
      }));

      const response = await API.post('/collection-points/add-recycling-entry', {
        collectionPointId: collectionPoint._id,
        userId,
        recyclingHistory, // Attach detailed recycling data to the request
      });

      if (response.status === 200) {
        alert("Recycling request submitted successfully!");
        closePopup();
        window.location.href = '/profile';
      } else {
        throw new Error("Failed to submit recycling request.");
      }
    } catch (error) {
      if(error.status == 401){
        alert("You need to be logged in to submit a recycling request.");
      // Save the recycling request data to localStorage for later
      const recyclingRequest = {
        collectionPointId: collectionPoint._id,
        items,
      };
      localStorage.setItem("pendingRecyclingRequest", JSON.stringify(recyclingRequest));
      localStorage.setItem("requestExpiry", Date.now() + 60 * 60 * 1000); // Store expiry timestamp (1 hour)
      window.open('/login', "_blank"); // Redirect to login
      return;
      }
      console.error("Error submitting recycling request:", error);
      alert("There was an issue with your submission. Please try again later.");
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center" style={{ zIndex: 999 }}>
      <div className="bg-white p-6 rounded-lg shadow-lg w-96 max-h-[80vh] md:max-h-full overflow-y-scroll md:overflow-y-hidden space-y-6">
        
        <div className="flex items-center justify-between mb-4">
          <h4 className="text-[#34A853] text-xl ">Submit Recycling Request</h4>
          <button onClick={closePopup} className="close text-[#6E7D80] text-xl font-bold">
            X
          </button>
        </div>
        <p className="text-sm text-[#000000]">
          Select the items you wish to recycle, see your estimated points and environmental impact score.
        </p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <strong>{collectionPoint.name}</strong>
          <p className="text-sm text-gray-600">Choose items to submit</p>
          <div className=' overflow-y-scroll custom-scrollbar max-h-[40vh]'>
          {items.map((item, index) => (
            <div key={index} className="p-4 border rounded-lg space-y-4 mb-4 mr-4">
              <div className="space-y-2">
                <div>
                  <label htmlFor={`itemType-${index}`} className="block text-[#414042] text-sm font-medium">
                    Item Type
                  </label>
                  <select
                    id={`itemType-${index}`}
                    className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-[#29752B] text-sm"
                    value={item.itemType}
                    onChange={(e) => handleItemChange(index, 'itemType', e.target.value)}
                    required
                  >
                    <option value="">Select item</option>
                    {collectionPoint.acceptedItems.map((acceptedItem) => (
                      <option key={acceptedItem.name} value={acceptedItem.name}>
                        {acceptedItem.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label htmlFor={`condition-${index}`} className="block text-[#414042] text-sm font-medium">
                    Condition
                  </label>
                  <select
                    id={`condition-${index}`}
                    className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-[#29752B] text-sm"
                    value={item.condition}
                    onChange={(e) => handleItemChange(index, 'condition', e.target.value)}
                  >
                    <option value="">Select condition</option>
                    <option value="New">New</option>
                    <option value="Used">Used</option>
                    <option value="Damaged">Damaged</option>
                  </select>
                </div>
                <div>
                  <label htmlFor={`brandModel-${index}`} className="block text-[#414042] text-sm font-medium">
                    Brand/Model
                  </label>
                  <input
                    type="text"
                    id={`brandModel-${index}`}
                    className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-[#29752B] text-sm"
                    value={item.brandModel}
                    onChange={(e) => handleItemChange(index, 'brandModel', e.target.value)}
                    placeholder="e.g., Apple or Apple iPhone 12"
                  />
                </div>
                <div>
                  <label htmlFor={`weight-${index}`} className="block text-[#414042] text-sm font-medium">
                    Weight (in kg)
                  </label>
                  <input
                    type="number"
                    step="0.1"
                    id={`weight-${index}`}
                    className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-[#29752B] text-sm"
                    value={item.weight}
                    onChange={(e) => handleItemChange(index, 'weight', e.target.value)}
                    placeholder="Default weight will auto-fill"
                    required
                  />
                </div>
                <div>
                  <label htmlFor={`quantity-${index}`} className="block text-[#414042] text-sm font-medium">
                    Quantity
                  </label>
                  <input
                    type="number"
                    min="1"
                    id={`quantity-${index}`}
                    className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-[#29752B] text-sm"
                    value={item.quantity}
                    onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="border-t border-gray-200 pt-4">
                <p className="text-[#414042] text-sm">
                  Estimated Points: <span className="font-bold text-[#18421B]">{item.estimatedPoints.toFixed(2)} pts</span>
                </p>
                <p className="text-sm">
                  Environmental Impact Score: <span className="font-bold text-[#18421B]">{item.environmentalImpact}</span>
                </p>
              </div>
            </div>
          ))}
          
          <button type="button" onClick={handleAddItem} className="text-[#34A853] font-medium hover:underline">
            Add More Items
          </button>
          </div>

          

          <button type="submit" className="bg-[#34A853] text-white py-3 px-6 rounded-full w-full mt-6 hover:bg-[#206A29]">
            Submit Recycling Request
          </button>
        </form>
      </div>

    </div>
  );
}

export default RecyclingSubmissionPopup;
