import React, { useState } from "react";
import { Tooltip } from "react-tooltip";

const RecyclingHistory = ({ user }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Calculate the entries for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentEntries = user.recyclingHistory?.slice(startIndex, startIndex + itemsPerPage) || [];

  const totalPages = Math.ceil((user.recyclingHistory?.length || 0) / itemsPerPage);

  return (
    <div className="bg-[#FFFFFF] font-Inter p-6 rounded-2xl shadow-md max-w-7xl mx-auto border border-gray-300">
      {/* Header */}
      <div className="flex flex-col sm:flex-row items-center justify-between mb-4 space-y-4 sm:space-y-0">
        <h3 className="text-lg text-[#252C32]" data-aos="fade-right">
          My Recycling History
        </h3>
        <div className="flex items-center space-x-2" data-aos="fade-left" data-aos-delay="100">
          <button data-tooltip-id="tooltip-search" data-tooltip-content="Search your history">
            <img
              src="/images/search-icon.png"
              alt="Search"
              className="w-5 h-5 text-[#252C32] transition-transform duration-200 hover:scale-125"
            />
          </button>
          <div className="border-l border-gray-300 h-5"></div>
          <button data-tooltip-id="tooltip-toggle" data-tooltip-content="Toggle view">
            <img
              src="/images/icon_toggle_button.png"
              alt="Toggle"
              className="w-5 h-5 transition-transform duration-200 hover:scale-125"
            />
          </button>
          <button data-tooltip-id="tooltip-download" data-tooltip-content="Download history">
            <img
              src="/images/download-icon2.png"
              alt="Download"
              className="w-5 h-5 transition-transform duration-200 hover:scale-125"
            />
          </button>
          <button data-tooltip-id="tooltip-refresh" data-tooltip-content="Refresh data">
            <img
              src="/images/refresh-icon.png"
              alt="Refresh"
              className="w-5 h-5 transition-transform duration-200 hover:scale-125"
            />
          </button>
          <div className="border-l border-gray-300 h-5"></div>
          <button data-tooltip-id="tooltip-chart" data-tooltip-content="View chart">
            <div className="flex items-center transition-transform duration-200 hover:scale-110">
              <span className="text-[#252C32] text-sm">Chart view</span>
              <img src="/images/chart-icon.png" alt="Chart" className="w-5 h-5 ml-2" />
            </div>
          </button>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto border-b border-t border-gray-300">
        <table className="w-full border-collapse">
          <thead data-aos="fade-down" data-aos-delay="100">
            <tr className="text-[#6E7C87]">
              <th className="py-2 px-4 border-b border-gray-300">
                <input type="checkbox" className="accent-[#F6F8F9] border-[#B0BABF]" />
              </th>
              <th className="py-2 px-4 border-b border-gray-300">Collection Centre</th>
              <th className="py-2 px-4 border-b border-gray-300">Device Type</th>
              <th className="py-2 px-4 border-b border-gray-300">Status</th>
              <th className="py-2 px-4 border-b border-gray-300">Date</th>
              <th className="py-2 px-4 border-b border-gray-300">Points</th>
              <th className="py-2 px-4 border-b border-gray-300">Env. Impact</th>
              <th className="py-2 px-4 border-b border-gray-300">Export</th>
            </tr>
          </thead>
          <tbody>
            {currentEntries.length > 0 ? (
              currentEntries.map((entry, index) => (
                <tr key={index} className="text-[#252C32]" data-aos="fade-up" data-aos-delay="300">
                  <td className="py-2 px-4 border-b border-gray-300">
                    <input type="checkbox" className="accent-[#F6F8F9] border-[#B0BABF]" />
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {entry.collectionPoint?.name || "N/A"}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">{entry.deviceType}</td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <div className="flex items-center">
                      <span
                        className={`w-3 h-3 rounded-full ${
                          entry.status === "Completed"
                            ? "bg-[#38D9B9]"
                            : entry.status === "Processing"
                            ? "bg-[#FFC107]"
                            : "bg-[#DC3545]"
                        } mr-2`}
                      ></span>
                      {entry.status}
                    </div>
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {new Date(entry.date).toLocaleDateString()}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">{entry.pointsEarned}pts</td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <span className="flex md:items-center md:ml-6">{entry.environmentalImpact}</span>
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <button data-tooltip-id="tooltip-export" data-tooltip-content="Export data">
                      <img
                        src="/images/download-icon.png"
                        alt="Export"
                        className="w-5 h-5 transition-transform duration-200 hover:scale-125"
                      />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="py-4 px-4 text-center text-gray-500">
                  No recycling history available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between mt-4">
        <button
          className="bg-[#FFFFFF] text-[#344054] px-4 py-2 rounded-md flex items-center border border-[#DDE2E4] hover:bg-gray-100"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => prev - 1)}
        >
          <img src="/images/arrow-left-icon.png" alt="Previous" className="w-4 h-4 mr-2" />
          Previous
        </button>
        <span>
          <button className="bg-[#EEFAF7] text-[#4BA86D] px-3 py-1 rounded-md" data-aos="fade-up" data-aos-delay="100">{currentPage}</button>
        </span>
        <button
          className="bg-[#FFFFFF] text-[#344054] px-4 py-2 rounded-md flex items-center border border-[#DDE2E4] hover:bg-gray-100"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Next
          <img src="/images/arrow-right-icon.png" alt="Next" className="w-4 h-4 ml-2" />
        </button>
      </div>

      {/* Tooltip setup */}
      <Tooltip id="tooltip-search" />
      <Tooltip id="tooltip-toggle" />
      <Tooltip id="tooltip-download" />
      <Tooltip id="tooltip-refresh" />
      <Tooltip id="tooltip-chart" />
      <Tooltip id="tooltip-export" />
    </div>
  );
};

export default RecyclingHistory;
