import React, { useState } from 'react';
import API from '../../Services/api';

const RegisterStaff = () => {
  const [formData, setFormData] = useState({ name: '', email: '', password: '', role: '', collectionPointId: '' });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await API.post('/collection-staff/register', formData);
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.error || 'Registration failed');
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className="w-full max-w-md p-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-semibold mb-4 text-center">Register Staff</h2>
        <input
          name="name"
          placeholder="Name"
          onChange={handleChange}
          required
          className="w-full px-4 py-2 mb-3 border rounded-md focus:outline-none focus:border-blue-500"
        />
        <input
          name="email"
          type="email"
          placeholder="Email"
          onChange={handleChange}
          required
          className="w-full px-4 py-2 mb-3 border rounded-md focus:outline-none focus:border-blue-500"
        />
        <input
          name="password"
          type="password"
          placeholder="Password"
          onChange={handleChange}
          required
          className="w-full px-4 py-2 mb-3 border rounded-md focus:outline-none focus:border-blue-500"
        />
        <input
          name="role"
          placeholder="Role"
          onChange={handleChange}
          required
          className="w-full px-4 py-2 mb-3 border rounded-md focus:outline-none focus:border-blue-500"
        />
        <input
          name="collectionPointId"
          placeholder="Collection Point ID"
          onChange={handleChange}
          required
          className="w-full px-4 py-2 mb-3 border rounded-md focus:outline-none focus:border-blue-500"
        />
        <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700">
          Register
        </button>
        {message && <p className="mt-4 text-center text-sm text-red-500">{message}</p>}
      </form>
    </div>
  );
};

export default RegisterStaff;
