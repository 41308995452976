import React, { useState} from "react";
import CountUpWrapper from "../../CountUpWrapper";

const UserProfile = ({user}) => {
  const [showModal, setShowModal] = useState(false);

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };
  const handleLogout = () => {
    // Clear the token from localStorage
    localStorage.removeItem("token");
    
    // Redirect to the login page or homepage
    window.location.href = "/login";
  };
  

  return (
      <div className="bg-[#FFFFFF] font-Inter p-8 rounded-3xl shadow-md w-full max-w-lg mx-auto overflow-hidden"  data-aos="fade-in" data-aos-delay="100">
        {/* Progress Bar */}
        <div data-aos="fade-down" data-aos-delay="200" className="mx-auto relative w-11/12" >
            <div className="w-10/12">
            <div className="h-2 w-full bg-[#EEFAF7] rounded-full mb-8 overflow-hidden">
                <div className="h-full bg-[#29752B] rounded-full" style={{ width: "10%" }}></div>
            </div>
            </div>
            <span className="absolute right-5 top-1/2 transform -translate-y-1/2 text-sm text-[#000000]">10%</span>
        </div>
        {/* User Image */}
        <div className="flex items-center mb-6 relative">
          <div className="w-24 h-24 rounded-full overflow-hidden mr-4 relative" data-aos="fade-right" data-aos-delay="400">
            {user?.image ? (
              <img src={user?.image} alt="User" className="w-full h-full object-cover" />
            ) : (
              <div className="bg-[#A7D3BF] w-full h-full flex items-center justify-center text-[#18421B]  text-3xl">
                {user?.username ? user?.username.charAt(0) : "?"}
              </div>
            )}

           
          </div>

          {/* User Name and Email */}
          <div  data-aos="fade-right" data-aos-delay="400">
            <h2 className="text-2xl font-bold text-[#414042]">{user?.username || "No Address Found"}</h2>
            <p className="text-[#414042]">{user?.email || "Loading..."}</p>
          </div>

           {/* More Options Button */}
           <div className="absolute top-2 right-2"  data-aos="fade-left" data-aos-delay="500">
                <button onClick={handleModalToggle} className=" focus:outline-none"> {/* Added focus:outline-none */}
                <img src="/images/dots-vertical.png" alt="More options" className="w-4 h-4" />
                </button>
                
            </div>{/* Dropdown Menu (hidden by default) */}
                {showModal && (
                <div className="relative z-50">
                    <div className="fixed right-4 py-2 w-48 bg-none rounded-md shadow-lg">
                        <div className="bg-[#FFFFFF] p-6 rounded-lg  w-full max-w-sm">
                        <h3 className="text-xl  text-[#414042] mb-4" data-aos="fade-in" data-aos-delay="100">More Options</h3>
                        <div className=" overflow-x-hidden overflow-y-auto custom-scrollbar" style={{maxHeight: "calc(100vh - 69vh)"}}>
                        
                        <ul className="space-y-4">
                            <li>
                            <a  data-aos="fade-left" data-aos-delay="200" href="#" className="text-[#414042] hover:text-[#29752B] px-4 py-2 rounded-md mr-4 flex items-center border border-[DDE2E4] hover:bg-gray-100">
                                Edit Profile
                            </a>
                            </li>
                            <li>
                            <a href="#"  data-aos="fade-left" data-aos-delay="300" className="text-[#414042] hover:text-[#29752B] px-4 py-2 rounded-md mr-4 flex items-center border border-[DDE2E4] hover:bg-gray-100">
                                Settings
                            </a>
                            </li>
                            <li>
                            <a href="#"  data-aos="fade-left" data-aos-delay="400" className="text-[#414042] hover:text-[#29752B] px-4 py-2 rounded-md mr-4 flex items-center border border-[DDE2E4] hover:bg-gray-100">
                                Help & Support
                            </a>
                            </li>
                            <li>
                            <a onClick={handleLogout}  data-aos="fade-left" data-aos-delay="500" className="text-[#FFFFFF] bg-[#FF6666] hover:text-[#FF3333] px-4 py-2 rounded-md mr-4 flex items-center border border-[#FF6666] hover:bg-gray-100 hover:text-[#FF6666]">
                                Logout
                            </a>
                            </li>
                        </ul>
                        <button onClick={handleModalToggle}  data-aos="fade-left" data-aos-delay="600" className="mt-6 bg-[#65BC6A] text-white px-4 py-2 rounded-lg hover:bg-[#D9D9D9] hover:text-[#414042]">
                            Close
                        </button>
                        </div>
                        </div>
                    </div>
                </div>
                )}
        </div>

        {/* Address and Phone */}
        <div className="grid grid-cols-1 gap-4 mb-6">
          <div className="flex items-center">
            <span className="bg-[#18421B] text-white font-medium py-1 px-2 rounded-md mr-2"  data-aos="fade-right" data-aos-delay="300">Address</span>
            <p className="text-[#414042]" data-aos="fade-in" data-aos-delay="300">{user?.address || "Loading..."}</p>
          </div>
          <div className="flex items-center">
            <span className="bg-[#18421B] text-white font-medium py-1 px-2 rounded-md mr-2" data-aos="fade-right" data-aos-delay="400">Phone</span>
            <p className="text-[#414042]"  data-aos="fade-in" data-aos-delay="400">{user?.mobileNumber || "Loading..."}</p>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3  gap-4 md:pr-2">
        {/* Bin Icon and Count */}
        <div className="flex items-center mb-4 h-full">
          <img src="/images/recycle_bin.png" alt="Bin icon" className="h-12 w-12 mr-2"  data-aos="fade-in" data-aos-delay="400"/>
          <span className="text-[#000000] text-3xl font-bold md:mx-auto bg-[#7DD1E4] bg-opacity-20 p-2 rounded-lg"><CountUpWrapper start={0} end={user?.recyclingHistory.reduce((sum, entry) => sum + entry.itemsRecycled, 0)} duration={2} redraw={true} /></span>
          {/* <div className="tooltip">
            <span className="tooltiptext">You have completed 36 recycling</span>
          </div> */}
        </div>

        {/* World Map Icon and Count */}
        <div className="flex items-center mb-4 h-full">
          <img src="/images/world-map-icon2.png" alt="World map icon" className="h-12 w-12 mr-2" data-aos="fade-in" data-aos-delay="500" />
          <span className="text-[#000000] text-3xl font-bold md:mx-auto bg-[#7DD1E4] bg-opacity-20 p-2 rounded-lg"><CountUpWrapper start={0} end={15} duration={2} redraw={true} /></span>
          {/* <div className="tooltip">
            <span className="tooltiptext">You've saved 15 lbs of e-waste from landfills!</span>
          </div> */}
        </div>
        {/* Eco Saver Badge and Messages */}
        <div className="grid items-center grid-rows-2 gap-2 hidden md:block">
          <button className="bg-[#FFFFFF] text-[#000000] px-4 py-2 rounded-xl mr-4 flex items-center border border-[DDE2E4] hover:bg-gray-100 whitespace-nowrap mb-2"data-aos="fade-in" data-aos-delay="600">
            Eco Saver <span className="bg-[#65BC6A] text-[#FFFFFF] font-normal px-1 rounded-[999px] ml-1"><CountUpWrapper start={0} end={2} duration={2} redraw={true} /></span>
          </button>
          <button className="bg-[#FFFFFF] text-[#000000] px-4 py-2 rounded-xl mr-4 flex items-center border border-[DDE2E4] hover:bg-gray-100" data-aos="fade-in" data-aos-delay="700">
            Messages <span className="bg-[#FF6666] text-[#FFFFFF] font-normal px-1 rounded-[999px] ml-1"><CountUpWrapper start={0} end={4} duration={2} redraw={true} /></span>
          </button>
        </div>
        </div>
        <div className="md:hidden">
        {/* Eco Saver Badge and Messages */}
        <div className="grid mt-4 items-center grid-cols-2 gap-2">
          <button className="bg-[#FFFFFF] text-[#000000] p-2 rounded-xl mr-4 items-center border border-[DDE2E4] hover:bg-gray-100"data-aos="fade-in" data-aos-delay="600">
            Eco Saver <span className="bg-[#65BC6A] text-[#FFFFFF] font-normal px-1 rounded-[999px] ml-1 items-end"><CountUpWrapper start={0} end={2} duration={2} redraw={true} /></span>
          </button>
          <button className="bg-[#FFFFFF] text-[#000000] p-2 rounded-xl mr-4 items-center border border-[DDE2E4] hover:bg-gray-100" data-aos="fade-in" data-aos-delay="700">
            Messages <span className="bg-[#FF6666] text-[#FFFFFF] font-normal px-1 rounded-[999px] ml-1 items-end"><CountUpWrapper start={0} end={4} duration={2} redraw={true} /></span>
          </button>
        </div>
        </div>
      </div>
  );
};

export default UserProfile;