import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";


const About = () => {
  const navigate = useNavigate(); // React Router navigate function

  const handleMarkerClick = () => {
    navigate("/map");
  };
  return (
    <section className="bg-[#F8FAFB] py-3 px-6 md:px-6 overflow-hidden" id="about">
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        {/* UK Map with Markers */}
        <div className="relative w-full md:w-[626px] mb-10 md:mb-0"  data-aos="fade-right" data-aos-delay="100">
        <img 
            src="/images/uk_map.png" 
            alt="UK Map" 
            className="w-[626px] md:h-[917px]" 
        />
        {/* Scattered Map Markers */}
        {[
            { top: "14%", left: "35%" },
            { top: "18%", left: "45%" },
            { top: "23%", left: "43%" },
            { top: "28%", left: "45%" },
            { top: "32%", left: "42%" },
            { top: "43%", left: "58%" },
            { top: "48%", left: "15%" },
            { top: "55%", left: "60%" },
            { top: "60%", left: "54%" },
            { top: "63%", left: "60%" },
            { top: "68%", left: "68%" },
            { top: "75%", left: "75%" },
            { top: "78%", left: "40%" },
            { top: "85%", left: "58%" }, 
        ].map((marker, index) => (
            <img
            key={index}
            src="/images/_Map_location_marker.png"
            alt="Map Marker"
            style={{ top: marker.top, left: marker.left, cursor: 'pointer' }}
            className="absolute w-6 h-6 animate-pulse animate-faster about_map_marker" 
            onClick={handleMarkerClick}
            />
        ))}
        <Tooltip anchorSelect=".about_map_marker" place="top">
          View Interactive Map
        </Tooltip>
        </div>

        {/* About e-save Text and Button */}
        <div className="w-full md:w-1/2 md:pl-16 space-y-6" data-aos="fade-left" data-aos-delay="100"> 
          <h2 className="text-3xl md:text-4xl font-bold" data-aos="fade-left">
            About <span className="text-[#59CBAC]">e-save</span>
          </h2>
          <p className="text-lg text-black leading-relaxed"  data-aos="fade-left">
            At e-save, we believe in the power of repurposing old electronics 
            to reduce electronic waste and improve our community's health.
          </p>
          <p className="text-lg text-black leading-relaxed"  data-aos="fade-left" >
            Our team is dedicated to finding innovative solutions for recycling 
            electronic devices and creating a sustainable future for generations 
            to come.
          </p>
          <p className="text-lg text-black leading-relaxed"  data-aos="fade-left">
            Join us in our mission to make a difference by responsibly 
            disposing of electronic waste and contributing to a healthier 
            environment.
          </p>
          <button onClick={() => window.location.href = '/register'} className="w-full md:w-auto px-8 py-2 pr-2 bg-[#FFFFFF] text-[#294437] rounded-full border border-[#294437] font-semibold flex items-center justify-center relative hover:bg-[#294437] hover:border-white hover:text-white" data-aos="fade-left" data-aos-delay="400"> 
            <span className="mr-3">Get Started</span>
            <div className="bg-[#294437] p-3 rounded-full ml-3"> 
              <FontAwesomeIcon icon={faArrowRight} className="w-5 h-5 text-white" />
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default About;