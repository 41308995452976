import React, { useState, useEffect } from "react";
import CountUpWrapper from "../../CountUpWrapper";

const Dashboard = ({ user }) => {
  const [selectedDays, setSelectedDays] = useState(7);
  const [stats, setStats] = useState({
    points: 0,
    devicesRecycled: 0,
    environmentalImpact: 0,
  });

  // Function to calculate stats based on selected days
  useEffect(() => {
    if (!user || !user.recyclingHistory) return;

    const filteredHistory = user.recyclingHistory.filter((entry) => {
      const today = new Date();
      const entryDate = new Date(entry.date);
      const diffInDays = (today - entryDate) / (1000 * 60 * 60 * 24);
      return diffInDays <= selectedDays;
    });

    const totalPoints = filteredHistory.reduce((sum, entry) => sum + entry.pointsEarned, 0);
    const totalDevicesRecycled = filteredHistory.reduce((sum) => sum + 1, 0);
    const totalImpact = filteredHistory.reduce((sum, entry) => sum + entry.environmentalImpact, 0);

    setStats({
      points: totalPoints,
      devicesRecycled: totalDevicesRecycled,
      environmentalImpact: totalImpact,
    });
  }, [selectedDays, user]);

  return (
    <div className="bg-[#FFFFFF] font-Inter p-4 md:p-6 rounded-3xl shadow-md mx-auto max-w-5xl border border-gray-300">
      <div className="flex flex-col md:flex-row items-center justify-between mb-4 md:mb-6 space-y-4 md:space-y-0">
        <h2
          className="text-lg md:text-xl font-bold text-[#414042]"
          data-aos="fade-right"
          data-aos-delay="100"
        >
          Dashboard
        </h2>
        <div
          className="relative inline-block text-sm text-[#414042]"
          data-aos="fade-left"
          data-aos-delay="200"
        >
          <select
            value={selectedDays}
            onChange={(e) => setSelectedDays(parseInt(e.target.value, 10))}
            className="appearance-none bg-transparent text-[#414042] pr-6 pl-2 py-1 border border-[#DDE2E4] rounded-md cursor-pointer focus:outline-none"
          >
            <option value="7">Last 7 days</option>
            <option value="14">Last 14 days</option>
            <option value="30">Last 30 days</option>
            <option value={new Date().getDate()}>Current Month</option>
            <option value={new Date().getDate() + 30}>Last Month</option>
          </select>
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none text-[#414042]">
            ▼
          </span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6 items-center">
        {/* Stats Section */}
        <div className="grid sm:grid-cols-4 gap-4 items-center mx-auto">
          <div
            className="md:flex md:flex-row justify-between grid grid-cols-2 grid-rows-2 gap-12 md:gap-4 md:grid-cols-4 md:grid-rows-1"
            data-aos="fade-right"
            data-aos-delay="300"
          >
            {/* Points Earned */}
            <div className="flex-grow border-l border-gray-300 ml-6 pl-6 md:border-none md:pl-0 md:ml-auto">
              <span className="text-sm text-[#252C32] block mb-1 md:mb-2 whitespace-nowrap">
                Points Earned
              </span>
              <span className="text-[#252C32] text-2xl md:text-3xl font-bold">
                <CountUpWrapper start={0} end={stats.points} duration={2} redraw={false} />
                <span className="text-lg">pts</span>
              </span>
            </div>

            {/* Devices Recycled */}
            <div className="flex-grow border-l border-gray-300 pl-2 md:pl-4 mx-auto">
              <span className="text-sm text-[#252C32] block mb-1 md:mb-2 whitespace-nowrap">
                Devices Recycled
              </span>
              <span className="text-[#252C32] text-2xl md:text-3xl font-bold">
                <CountUpWrapper start={0} end={stats.devicesRecycled} duration={2} redraw={false} />
              </span>
            </div>

            {/* Environmental Impact */}
            <div className="flex-grow border-l border-gray-300 pl-2 md:pl-3 mx-auto">
              <span className="text-sm text-[#252C32] block mb-1 md:mb-2 whitespace-nowrap">
                Env. Impact
              </span>
              <span className="text-[#252C32] text-2xl md:text-3xl font-bold flex items-center">
                <img
                  src="/images/green-arrow-up.png"
                  alt="Arrow up"
                  className="w-4 h-4 mr-1"
                />
                <CountUpWrapper start={0} end={stats.environmentalImpact} duration={2} redraw={false} />
              </span>
            </div>

            {/* Status Indicators */}
            <div className="grid grid-rows-2 gap-1 md:gap-2">
              <div className="flex items-center">
                <span className="w-3 h-3 rounded-full bg-[#38D9B9] mr-1 md:mr-2"></span>
                <span className="text-xs md:text-sm text-[#414042]">Completed</span>
              </div>
              <div className="flex items-center">
                <span className="w-3 h-3 rounded-full bg-[#FFDE00] mr-1 md:mr-2"></span>
                <span className="text-xs md:text-sm text-[#414042]">Processing</span>
              </div>
            </div>
          </div>
          
        </div>

        {/* Actions Section */}
        <div className="flex flex-col sm:flex-row items-center justify-end space-y-2 sm:space-y-0 sm:space-x-1 md:space-x-4">
          <button className="flex items-center bg-white text-black px-4 py-2 rounded-md border border-[#DDE2E4] hover:bg-gray-100" data-aos="fade-left" data-aos-delay="400">
            <img src="/images/share_icon.png" alt="Share icon" className="w-4 h-4 mr-2" />
            Invite Friends
          </button>
          <button className="flex items-center bg-[#398F3E] text-white px-4 py-2 rounded-md hover:bg-[#206A29]" data-aos="fade-left" data-aos-delay="500">
            <img src="/images/plug_icon.png" alt="Plug icon" className="w-4 h-4 mr-2" />
            Recycle Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
