// CountUpWrapper.js
import React, { memo } from "react";
import CountUp from "react-countup";

const CountUpWrapper = memo(({ start = 0, end, duration = 2, decimals = 0, ...props }) => {
  return (
    <CountUp
      start={start}
      end={end}
      duration={duration}
      decimals={decimals}
      redraw={false} // Disable redraw to avoid animation trigger on re-renders
      preserveValue={true} // Preserve the value across renders
      {...props}
    />
  );
});

export default CountUpWrapper;