// src/components/Rewards/RewardList.js
import { useEffect, useState } from 'react';
import API from '../../Services/api';
import RedeemButton from './RewardButton'
function RewardList() {
  const [rewards, setRewards] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const response = await API.get('/rewards');
        setRewards(response.data);
      } catch (err) {
        setError('Error fetching rewards');
      }
    };

    fetchRewards();
  }, []);

  return (
    <div className="max-w-md mx-auto mt-10 p-5 border border-gray-200 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Available Rewards</h2>
      {error && <p className="text-red-500">{error}</p>}
      <ul>
        {rewards.map((reward) => (
          <li key={reward._id} className="p-4 border-b border-gray-200">
            <p><strong>Type:</strong> {reward.type}</p>
            <p><strong>Description:</strong> {reward.description}</p>
            <p><strong>Points Required:</strong> {reward.pointsRequired}</p>
            {reward.expiryDate && <p><strong>Expiry Date:</strong> {new Date(reward.expiryDate).toLocaleDateString()}</p>}
            <RedeemButton rewardId={reward._id} pointsRequired={reward.pointsRequired} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default RewardList;
