import axios from 'axios';

const API = axios.create({
  baseURL: 'https://backend.e-save.org.uk/api/v1', // Adjust if different
  headers: { 'Content-Type': 'application/json' },
});

// Automatically include the correct token based on the user type (staff or regular user)
API.interceptors.request.use((config) => {
  const staffToken = localStorage.getItem('staff-token');
  if (staffToken) {
    config.headers.Authorization = `Bearer ${staffToken}`; // Use staff token for staff routes
  } else {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Use user token for regular routes
    }
  }
  return config;
});

export default API;
