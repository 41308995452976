// src/components/Auth/Register.js
import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet-control-geocoder';
import API from '../../Services/api';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    mobileNumber: '',
    countryCode: '+44',
    password: '',
    passwordConfirm: '',
    address: '',
    latitude: '',
    longitude: '',
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);


  // Preload resources
  useEffect(() => {
    const images = Array.from(document.getElementsByTagName('img'));
    const fonts = ['Inter', 'Montserrat'];

    Promise.all([
      Promise.all(
        images.map(
          (img) =>
            new Promise((resolve, reject) => {
              img.onload = resolve;
              img.onerror = reject;
            })
        )
      ),
      Promise.all(fonts.map((font) => document.fonts.load(`1em ${font}`))),
    ])
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  // Carousel slider
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % 3);
    }, 4000); // Change slide every 4 seconds
    return () => clearInterval(interval);
  }, []);

  // Initialize AOS animations
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
      offset: 120,
    });
  }, []);

  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));


    if (name === 'address') {
      verifyAddress(value);
    }
  };

  // Address geocoding and verification
  const verifyAddress = (address) => {
    const geocoder = L.Control.Geocoder.nominatim();
    geocoder.geocode(address, (results) => {
      if (results.length > 0) {
        const { lat, lng } = results[0].center;
        setFormData((prev) => ({
          ...prev,
          latitude: lat,
          longitude: lng,
        }));
        setAddressError(null);
      } else {
        setAddressError('Location cannot be verified. Please enter a valid address.');
      }
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === 'password' || name === 'passwordConfirm') {
      if (formData.password !== formData.passwordConfirm) {
        setError('Passwords do not match.');
      } else {
        setError(null);
      }
    }

    try {
      await API.post('/users/register', formData);
      setSuccess('Registration successful! Redirecting to login...');
      setError(null);

      setTimeout(() => {
        window.location.href = '/login';
      }, 3000); // 3-second delay before redirecting

      setFormData({
        username: '',
        email: '',
        mobileNumber: '',
        countryCode: '+44',
        password: '',
        passwordConfirm: '',
        address: '',
        latitude: '51.519697324452714',
        longitude: '-0.11004609207697316',
      });
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred during registration.');
    }
  };
  return (
    <section className="bg-gray-100 font-Inter py-12 px-6 md:px-12 min-h-screen flex flex-col lg:flex-row items-center justify-center">
      {isLoading ? ( 
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-white bg-opacity-75">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-[#29752B]"></div>
        </div>
      ) : (
      <div className="container mx-auto flex flex-col md:flex-row items-center overflow-hidden bg-white rounded-3xl py-8">
        
        {/* Form Section */}
        <div className="p-8 lg:w-1/3 mb-12 lg:mb-0" data-aos="fade-right" data-aos-delay="100">
          <div className="flex items-center justify-center mb-6">
            <a href="/">
              <img src="/images/second_logo.png" alt="e-save Logo" className="h-10 lg:h-10" />
            </a>
          </div>
          <h2 className="text-3xl font-bold text-gray-800 text-center mb-6">Create your account</h2>
          <p className="text-gray-600 text-center mb-8">Join e-save to start your recycle journey.</p>
          {error && <div className="text-red-500 text-center mb-4">{error}</div>}
          {success && <div className="text-green-500 text-center mb-4">{success}</div>}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <img src="/images/Username_icon.png" alt="Username icon" className="absolute top-1/2 left-4 transform -translate-y-1/2 h-[24px] w-[20px]" />
              <input
                type="text"
                name="username"
                className="w-full h-[45px] border border-gray-400 pl-12 pr-4 py-3 rounded-lg focus:outline-none focus:border-[#398F3E] focus:ring-1 focus:ring-[#398F3E]"
                placeholder="Full Name"
                value={formData.username}
                onChange={handleChange}
                required
              />
            </div>
            
            <div className="relative">
              <img src="/images/Mail_icon.png" alt="Mail icon" className="absolute top-1/2 left-4 transform -translate-y-1/2 h-[17px] w-[20px]" />
              <input
                type="email"
                name="email"
                className="w-full h-[45px] border border-gray-400 pl-12 pr-4 py-3 rounded-lg focus:outline-none focus:border-[#398F3E] focus:ring-1 focus:ring-[#398F3E]"
                placeholder="Email e.g.name@mail.com"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="flex space-x-2">
              <select
                name="countryCode"
                value={formData.countryCode}
                onChange={handleChange}
                className="w-1/4 h-[45px] border border-gray-400 rounded-lg focus:outline-none focus:border-[#398F3E] focus:ring-1 focus:ring-[#398F3E]"
              >
                <option value="+44">+44</option>
                <option value="+1">+1</option>
                <option value="+91">+91</option>
                {/* Add more country codes as needed */}
              </select>
              <div className="relative w-3/4">
                {/* <img src="/images/Phone_icon.png" alt="Phone icon" className="absolute top-1/2 left-4 transform -translate-y-1/2 h-[22.5px] w-[16.18px]" /> */}
                <input
                  type="text"
                  name="mobileNumber"
                  className="w-full h-[45px] border border-gray-400 pl-2 pr-4 py-3 rounded-lg focus:outline-none focus:border-[#398F3E] focus:ring-1 focus:ring-[#398F3E]"
                  placeholder="Mobile Number"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            {/* Address */}
            <div className="relative">
            <img src="/images/address_icon.png" alt="Address icon" className="absolute top-1/2 left-4 transform -translate-y-1/2 h-[21px] w-[20px]" />
              <input
                type="text"
                name="address"
                placeholder="Enter your address"
                value={formData.address}
                onChange={handleChange}
                className="w-full h-[45px] border border-gray-400 pl-12 pr-4 py-3 rounded-lg focus:outline-none focus:border-[#398F3E] focus:ring-1 focus:ring-[#398F3E]"
                required
              />
              
            </div>
            {addressError && <p className="text-red-500 mt-2">{addressError}</p>}

            <div className="relative">
              <img
                src="/images/Password_icon.png"
                alt="Password icon"
                className="absolute top-1/2 left-4 transform -translate-y-1/2 h-[20px] w-[20px]"
              />
              <input
                type={showPassword ? "text" : "password"} // Toggle between 'text' and 'password'
                name="password"
                className="w-full h-[45px] border border-gray-400 pl-12 pr-4 py-3 rounded-lg focus:outline-none focus:border-[#398F3E] focus:ring-1 focus:ring-[#398F3E]"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>

            <div className="relative">
              <img
                src="/images/Password_icon.png"
                alt="Password icon"
                className="absolute top-1/2 left-4 transform -translate-y-1/2 h-[20px] w-[20px]"
              />
              <input
                type={showPassword ? "text" : "password"} // Toggle between 'text' and 'password'
                name="passwordConfirm"
                className="w-full h-[45px] border border-gray-400 pl-12 pr-4 py-3 rounded-lg focus:outline-none focus:border-[#398F3E] focus:ring-1 focus:ring-[#398F3E]"
                placeholder="Confirm Password"
                value={formData.passwordConfirm}
                onChange={handleChange}
                required
              />
            </div>

            {/* Show Password Checkbox */}
            <div className="mt-2 flex items-center txrt">
            <input
                type="checkbox"
                id="showPassword"
                checked={showPassword}
                onChange={() => setShowPassword((prev) => !prev)} // Toggle showPassword state
                className="mr-2 h-4 w-4 border-2 border-[#3f3f3f] rounded appearance-none cursor-pointer focus:ring-2 focus:ring-blue-500 checked:bg-[#398F3E] checked:border-[#398F3E] relative"
                style={{ position: "relative" }}
              />


              <label htmlFor="showPassword" className="text-gray-600 text-sm">
                Show Password
              </label>
            </div>

            {error && <div className="text-red-500 text-center mb-4">{error}</div>}
            {success && <div className="text-green-500 text-center mb-4">{success}</div>}

            <button
              type="submit"
              className="w-full bg-[#398F3E] text-white font-medium py-3 rounded-lg hover:bg-[#206A29] focus:outline-none"
            >
              Sign up
            </button>

            {/* "or" separator */}
            <div className="relative">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-white px-2 text-gray-500 z-10">or</span>
              </div>
            </div>

            {/* <button className="w-full bg-gray-100 border border-gray-200 flex items-center justify-center py-3 rounded-lg hover:bg-gray-300">
              <img src="/images/Google_logo.png" alt="Google logo" className="h-6 mr-2" />
              <span className="font-bold">Sign up with Google</span>
            </button> */}
            <div className="text-center">
              <p className="text-gray-800">
                Don't have an account? 
                <a href="/login" className="text-[#398F3E] hover:underline">
                  Sign in
                </a>
              </p>
            </div>
          </form>
        </div>
        
        {/* Image Carousel Section */}
        <div className="w-full lg:w-2/3 relative hidden md:block" data-aos="fade-left" data-aos-delay="200">
          <div className="w-full md:w-[500px] lg:w-[721px] h-[300px] md:h-[500px] lg:h-[724px] relative overflow-hidden rounded-3xl shadow-md mx-auto bg-[#f3f4f6]">
            {/* Carousel Images */}
            <div className="md:w-[455px] md:h-[302px] overflow-hidden mx-auto mb-6 mt-3 md:mt-20"> {/* Added mx-auto and mb-6 */}
              <div className="flex transform transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                <img src="/images/img_carousel1.png" alt="Carousel 1" className="w-full h-full object-cover shrink-0" />
                <img src="/images/img_carousel2.png" alt="Carousel 2" className="w-full h-full object-cover shrink-0" />
                <img src="/images/img_carousel3.png" alt="Carousel 3" className="w-full h-full object-cover shrink-0" />
              </div>
            </div>

            {/* Text Overlay */}
            <div className="md:absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-full">
              <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold text-black mb-4">Recycle, Track and Earn</h3>
              <p className="text-black mb-6">Locate nearby e-waste centres</p>
              {/* Carousel Dots */}
              <div className="flex justify-center space-x-3">
                {[0, 1, 2].map((dot) => (
                <span key={dot} className={`h-2 w-2 rounded-full inline-block ${dot === currentSlide ? "bg-gray-800" : "bg-gray-400"}`}></span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </section>
  );
};

export default Register;
