import React, { useState, useEffect } from "react"; 
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Header = ({ isDashboard, isExternal, isAuthenticated, user }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleLogout = () => {
    // Clear the token from localStorage
    localStorage.removeItem("token");
    
    // Redirect to the login page or homepage
    window.location.href = "/login";
  };
  

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  // Add scroll event listener to change styles when scrolled
  useEffect(() => {
    const handleScroll = () => {
      const heroSectionHeight = document.querySelector(".hero-section")?.offsetHeight || 0;
      setIsScrolled(window.scrollY > heroSectionHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
      offset: 120,
    });
  }, []);
  useEffect(() => {
    // Scroll to the target section when the component mounts, but only if redirected from another page
    const urlParams = new URLSearchParams(window.location.search);
    const scrollTo = urlParams.get("scrollTo");
  
    if (scrollTo ) {
      smoothScroll(scrollTo);
      // Clear the scrollTo parameter from the URL
    window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);
    // Function for smooth scrolling
    const smoothScroll = (targetId) => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      };

      const handleNavigation = (e, targetId, externalPage = false) => {
        e.preventDefault();
        const currentPath = window.location.pathname;
    
        if (externalPage) {
          // Redirect to external page with scroll parameter
          window.location.href = `/${targetId}`;
        } else if (currentPath === "/") {
          // Smooth scroll on the same page
          smoothScroll(targetId);
        } else {
          // Navigate to the home page with scroll parameter
          window.location.href = `/?scrollTo=${targetId}`;
        }
      };
  return (
    <header
        className={`z-[9999] overflow-hidden transition-all duration-300 ${
            isScrolled || isDashboard ? "bg-[#F8FAFB] text-[#172A3A]" : "bg-[#004d3f] text-white"
        } border-b border-solid ${isDashboard ? "" : "sticky top-0"} w-full`} 
    >
      <div className="flex lg:mx-12 items-center justify-between px-6 py-6">
        {/* Logo */}
        <div className="text-2xl font-bold flex items-center">
          <a href="/">
            <img src={isScrolled || isDashboard ? "/images/second_logo.png" : "/images/header_logo.png"} alt="e-save Logo" className="h-10 lg:h-10" data-aos="fade-right" data-aos-delay="100"/>
          </a>
        </div>

        {/* Desktop Navigation */}
        <ul
          className={`hidden lg:flex space-x-8 items-center ${
            isScrolled || isDashboard ? "text-[#172A3A] text-lg" : "text-lg"
          } flex-grow justify-center`}
        >
          <li>
            <a
              href="#about"
              className={`transition duration-300 ease-in-out py-3 px-4 rounded-full ${
                isScrolled || isDashboard ? "hover:bg-[#D9D9D9] hover:text-[#29752B]" : "hover:bg-[#1B362E]/65 text-white hover:text-yellow-500"
              }`}
              onClick={(e) => handleNavigation(e, "about")} data-aos="fade-down" data-aos-delay="100"
            >
              About e-save
            </a>
          </li>
          <li>
            <a
              href="#how-it-works"
              className={`transition duration-300 ease-in-out py-3 px-4 rounded-full ${
                isScrolled || isDashboard ? "hover:bg-[#D9D9D9] hover:text-[#29752B]" : "hover:bg-[#1B362E]/65 text-white hover:text-yellow-500"
              }`}
              onClick={(e) => handleNavigation(e, "how-it-works")} data-aos="fade-down" data-aos-delay="200"
            >
              How it works
            </a>
          </li>
          <li>
            <a
              href="#benefits"
              className={`transition duration-300 ease-in-out py-3 px-4 rounded-full ${
                isScrolled || isDashboard ? "hover:bg-[#D9D9D9] hover:text-[#29752B]" : "hover:bg-[#1B362E]/65 text-white hover:text-yellow-500"
              }`}
              onClick={(e) => handleNavigation(e, "benefits")} data-aos="fade-down" data-aos-delay="300"
            >
              Benefits
            </a>
          </li>
          <li>
            <a
              href="#map"
              className={`transition duration-300 ease-in-out py-3 px-4 rounded-full ${
                isScrolled || isDashboard ? "hover:bg-[#D9D9D9] hover:text-[#29752B]" : "hover:bg-[#1B362E]/65 text-white hover:text-yellow-500"
              }`}
              onClick={(e) => handleNavigation(e, "map")} data-aos="fade-down" data-aos-delay="400"
            >
              Map
            </a>
          </li>
        </ul>
        
        {/* Conditional rendering for dashboard/non-dashboard elements */}
        {isAuthenticated ? (
          // Dashboard elements
          <div className="hidden lg:flex items-center space-x-4"  data-aos="fade-down" data-aos-delay="500">
            <div className="relative">
              <input
                type="text"
                placeholder="Search location or city"
                className={`border border-gray-300 px-4 py-2 rounded-lg pl-10 focus:outline-none focus:ring-1 focus:ring-[#29752B]`}
              />
              <img src="/images/search-icon.png" alt="Search" className="absolute top-1/2 left-3 transform -translate-y-1/2 h-5 w-5" />
            </div>
            <button className="notification"  data-aos="fade-down" data-aos-delay="600">
              <img src="/images/notification-icon.png" alt="Notification" className="w-6 h-6" />
            </button>
            <button className="user_prof" onClick={()=>{window.location.href = "/profile"}}>
              <img src="/images/user-profile-picture.png" alt="User Profile" className="w-8 h-8 rounded-full"  data-aos="fade-left" data-aos-delay="700" />
            </button>
            {showModal && (
                      <div className="relative z-[9999]">
                          <div className="fixed right-4 py-2 w-48 bg-none rounded-md shadow-lg">
                              <div className="bg-[#FFFFFF] p-6 rounded-lg  w-full max-w-sm">
                              <h3 className="text-xl  text-[#414042] mb-4" data-aos="fade-in" data-aos-delay="100">More Options</h3>
                              <div className=" overflow-x-hidden overflow-y-auto custom-scrollbar" style={{maxHeight: "calc(100vh - 69vh)"}}>
                              
                              <ul className="space-y-4">
                                  <li>
                                  <a  data-aos="fade-left" data-aos-delay="200" href="#" className="text-[#414042] hover:text-[#29752B] px-4 py-2 rounded-md mr-4 flex items-center border border-[DDE2E4] hover:bg-gray-100">
                                      Edit Profile
                                  </a>
                                  </li>
                                  <li>
                                  <a href="#"  data-aos="fade-left" data-aos-delay="300" className="text-[#414042] hover:text-[#29752B] px-4 py-2 rounded-md mr-4 flex items-center border border-[DDE2E4] hover:bg-gray-100">
                                      Settings
                                  </a>
                                  </li>
                                  <li>
                                  <a href="#"  data-aos="fade-left" data-aos-delay="400" className="text-[#414042] hover:text-[#29752B] px-4 py-2 rounded-md mr-4 flex items-center border border-[DDE2E4] hover:bg-gray-100">
                                      Help & Support
                                  </a>
                                  </li>
                                  <li>
                                  <a onClick={handleLogout}  data-aos="fade-left" data-aos-delay="500" className="text-[#FFFFFF] bg-[#FF6666] px-4 py-2 rounded-md mr-4 flex items-center border border-[#FF6666] hover:bg-gray-100 hover:text-[#FF6666]">
                                      Logout
                                  </a>
                                  </li>
                              </ul>
                              <button onClick={handleModalToggle}  data-aos="fade-left" data-aos-delay="600" className="mt-6 bg-[#65BC6A] text-white px-4 py-2 rounded-lg hover:bg-[#D9D9D9] hover:text-[#414042]">
                                  Close
                              </button>
                              </div>
                              </div>
                          </div>
                      </div>
                    )}
          <Tooltip anchorSelect=".user_prof" place="bottom" style={{ zIndex: 10000 }}>
              Hi, {user.username || "User"}!
          </Tooltip>
          <Tooltip anchorSelect=".notification" place="bottom" style={{ zIndex: 10000 }}>
            Notifications
          </Tooltip>
          </div>
        ) : (
          // Login and Register buttons
          <div className="hidden lg:flex space-x-4">
            <a
              href="/login"
              className={`transition duration-300 ease-in-out border ${
                isScrolled ? "border-[#29752B] text-[#29752B]" : "border-blue_gray-100 text-white"
              } px-4 lg:px-6 py-2 rounded-full ${
                isScrolled
                  ? "hover:bg-[#D9D9D9] hover:text-[#96d9c9] hover:border-[#96d9c9]"
                  : "hover:text-yellow-500 hover:bg-[#1B362E]/65 hover:border-yellow-500"
              }`} data-aos="fade-left" data-aos-delay="500"
            >
              Login
            </a>
            <a
              href="/register"
              className={`transition duration-300 ease-in-out ${
                isScrolled
                  ? "bg-[#29752B] text-white border-[#29752B]"
                  : "bg-[#1B362E]/65 border border-blue_gray-100 text-white"
              } px-4 lg:px-6 py-2 rounded-full ${
                isScrolled
                  ? "hover:bg-[#96d9c9]"
                  : "hover:text-yellow-500 hover:bg-transparent hover:border-yellow-500"
              }`} data-aos="fade-left" data-aos-delay="600"
            >
              Register
            </a>
          </div>
        )}

        {/* Mobile Menu Icon */}
        <div className="lg:hidden ml-auto">
        <button
            data-tooltip-id="menuTooltip"
            className={isScrolled ? "text-gray-800" :"text-white"}
            onClick={toggleMobileMenu}
          >
            <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} size="lg" />
          </button>
          {/* Tooltip Definition */}
          <Tooltip id="menuTooltip" place="bottom">
            {isMobileMenuOpen ? "Close Menu" : "Open Menu"}
          </Tooltip>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div
          className={`lg:hidden py-4 transition-all duration-300 ${
            isScrolled || isDashboard ? "bg-[#EBEDF0] text-[#172A3A]" : "bg-[#004d3f] text-white"
          }`}
        >
          <nav className="flex flex-col items-center space-y-4">
            <a
              href=""
              className={`transition duration-300 ease-in-out py-3 px-4 rounded-full ${
                isScrolled || isDashboard ? "hover:bg-[#D9D9D9]" : "hover:bg-[#1B362E]/65 text-white"
              }`}
              onClick={(e) => {
                handleNavigation(e, "about")
                toggleMobileMenu(); // Close the menu after click
              }}
              data-aos="fade-left" data-aos-delay="100"
            >
              About e-save
            </a>
            <a
              href=""
              className={`transition duration-300 ease-in-out py-3 px-4 rounded-full ${
                isScrolled || isDashboard ? "hover:bg-[#D9D9D9]" : "hover:bg-[#1B362E]/65 text-white"
              }`}
              onClick={(e) => {
                handleNavigation(e, "how-it-works")
                toggleMobileMenu(); // Close the menu after click
              }} data-aos="fade-left" data-aos-delay="200"
            >
              How it works
            </a>
            <a
              href="#benefits"
              className={`transition duration-300 ease-in-out py-3 px-4 rounded-full ${
                isScrolled || isDashboard ? "hover:bg-[#D9D9D9]" : "hover:bg-[#1B362E]/65 text-white"
              }`}
              onClick={(e) => {
                handleNavigation(e, "benefits")
                toggleMobileMenu(); // Close the menu after click
              }} data-aos="fade-left" data-aos-delay="300"
            >
              Benefits
            </a>
            <a
              href="#map"
              className={`transition duration-300 ease-in-out py-3 px-4 rounded-full ${
                isScrolled || isDashboard ? "hover:bg-[#D9D9D9]" : "hover:bg-[#1B362E]/65 text-white"
              }`}
              onClick={(e) => {
                handleNavigation(e, "map")
                toggleMobileMenu(); // Close the menu after click
              }} data-aos="fade-left" data-aos-delay="400"
            >
              Map
            </a>
            {isAuthenticated ? (
                // Dashboard elements for mobile (you can add mobile-specific elements here if needed)
                <div className="md:hidden flex flex-col items-center space-y-4">
                    <div className="relative w-full"  data-aos="fade-left" data-aos-delay="500"> 
                        <input
                        type="text"
                        placeholder="Search location or city"
                        className="border border-gray-300 px-4 py-2 rounded-lg pl-10 w-full focus:outline-none focus:ring-1 focus:ring-[#29752B]"
                        />
                        <img src="/images/search-icon.png" alt="Search" className="absolute top-1/2 left-3 transform -translate-y-1/2 h-5 w-5" />
                    </div>
                    <button className="w-12 notification"  data-aos="fade-left" data-aos-delay="600"> {/* Adjusted button size */}
                        <img src="/images/notification-icon.png" alt="Notification" className="w-10 h-10" />
                    </button>
                    <button className="w-12 rounded-full user_prof" onClick={()=>{window.location.href = "/profile"}}  data-aos="fade-left" data-aos-delay="700"> {/* Adjusted button size and added overflow-hidden */}
                        <img src="/images/user-profile-picture.png" alt="User Profile" className="w-10 h-10 object-cover" />
                    </button>
                    {showModal && (
                      <div className="relative z-[9999]">
                          <div className="fixed right-4 py-2 w-48 bg-none rounded-md shadow-lg">
                              <div className="bg-[#FFFFFF] p-6 rounded-lg  w-full max-w-sm">
                              <h3 className="text-xl  text-[#414042] mb-4" data-aos="fade-in" data-aos-delay="100">More Options</h3>
                              <div className=" overflow-x-hidden overflow-y-auto custom-scrollbar" style={{maxHeight: "calc(100vh - 69vh)"}}>
                              
                              <ul className="space-y-4">
                                  <li>
                                  <a  data-aos="fade-left" data-aos-delay="200" href="#" className="text-[#414042] hover:text-[#29752B] px-4 py-2 rounded-md mr-4 flex items-center border border-[DDE2E4] hover:bg-gray-100">
                                      Edit Profile
                                  </a>
                                  </li>
                                  <li>
                                  <a href="#"  data-aos="fade-left" data-aos-delay="300" className="text-[#414042] hover:text-[#29752B] px-4 py-2 rounded-md mr-4 flex items-center border border-[DDE2E4] hover:bg-gray-100">
                                      Settings
                                  </a>
                                  </li>
                                  <li>
                                  <a href="#"  data-aos="fade-left" data-aos-delay="400" className="text-[#414042] hover:text-[#29752B] px-4 py-2 rounded-md mr-4 flex items-center border border-[DDE2E4] hover:bg-gray-100">
                                      Help & Support
                                  </a>
                                  </li>
                                  <li>
                                  <a onClick={handleLogout}  data-aos="fade-left" data-aos-delay="500" className="text-[#FFFFFF] bg-[#FF6666] hover:text-[#FF3333] px-4 py-2 rounded-md mr-4 flex items-center border border-[#FF6666] hover:bg-gray-100 hover:text-[#FF6666]">
                                      Logout
                                  </a>
                                  </li>
                              </ul>
                              <button onClick={handleModalToggle}  data-aos="fade-left" data-aos-delay="600" className="mt-6 bg-[#65BC6A] text-white px-4 py-2 rounded-lg hover:bg-[#D9D9D9] hover:text-[#414042]">
                                  Close
                              </button>
                              </div>
                              </div>
                          </div>
                      </div>
                    )}<Tooltip anchorSelect=".user_prof" place="top" style={{ zIndex: 10000 }}>
            Hi, {user.username || "User"}!
          </Tooltip>
          <Tooltip anchorSelect=".notification" place="top" style={{ zIndex: 10000 }}>
            Notifications
          </Tooltip>
                    </div>
                    
                ) : (
                // Login and Register buttons for mobile
                <div className="flex flex-col items-center space-y-2"> {/* Changed to flex-col and added space-y-2 */}
                    <a
                    href="/login"
                    className={`transition duration-300 ease-in-out border w-full text-center ${ // Added w-full and text-center
                        isScrolled ? "border-[#29752B] text-[#29752B]" : "border-blue_gray-100 text-white"
                    } px-6 py-2 rounded-full ${
                        isScrolled ? "hover:bg-[#D9D9D9]" : "hover:text-yellow-500 hover:bg-[#1B362E]/65 hover:border-yellow-500"
                    }`}  data-aos="fade-left" data-aos-delay="500"
                    >
                    Login
                    </a>
                    <a
                    href="/register"
                    className={`transition duration-300 ease-in-out w-full text-center ${ // Added w-full and text-center
                        isScrolled
                        ? "bg-[#29752B] text-white border-[#29752B]"
                        : "bg-[#1B362E]/65 border border-blue_gray-100 text-white"
                    } px-6 py-2 rounded-full ${
                        isScrolled ? "hover:bg-[#29752B]" : "hover:text-yellow-500 hover:bg-transparent hover:border-yellow-500"
                    }`}  data-aos="fade-left" data-aos-delay="600"
                    >
                    Register
                    </a>
                </div>
                )}
            
          </nav>
          
        </div>
      )}
    </header>
  );
};

export default Header;