import React, { useState } from 'react';
import CollectionPointMap from '../Components/CollectionPoints/CollectionPointMap';
import CollectionPointList from '../Components/CollectionPoints/CollectionPointList';
import AddCollectionPoint from '../Components/CollectionPoints/AddCollectionPoint';

function CollectionPointsPage() {
    const [userLocation, setUserLocation] = useState([51.520405, -0.10819]); // Default London coordinates
  const isAdmin = localStorage.getItem('role') === 'admin'; // Simple check for admin role

  return (
    <div className="container mx-auto p-5">
      <h1 className="text-3xl font-bold mb-6">Collection Points</h1>

      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4">Map View</h2>
        <CollectionPointMap userLocation={userLocation} setUserLocation={setUserLocation} />
      </div>

      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4">List of Nearby Collection Points</h2>
        <CollectionPointList userLocation={userLocation} />
      </div>

      {isAdmin && (
        <div className="mb-6">
          <h2 className="text-2xl font-bold mb-4">Add New Collection Point</h2>
          <AddCollectionPoint />
        </div>
      )}
    </div>
  );
}

export default CollectionPointsPage;
