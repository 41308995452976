// src/components/Rewards/AddReward.js
import { useState } from 'react';
import API from '../../Services/api';

function AddReward() {
  const [formData, setFormData] = useState({
    type: 'discount',
    description: '',
    pointsRequired: '',
    expiryDate: '',
  });
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await API.post('/rewards/add', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Ensure admin token is included
        },
      });
      setMessage('Reward added successfully');
      setFormData({
        type: 'discount',
        description: '',
        pointsRequired: '',
        expiryDate: '',
      });
    } catch (err) {
      setError(err.response?.data?.error || 'Error adding reward');
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-5 border border-gray-200 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Add New Reward</h2>
      {message && <p className="text-green-500">{message}</p>}
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleSubmit}>
        <select
          name="type"
          value={formData.type}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
        >
          <option value="discount">Discount</option>
          <option value="voucher">Voucher</option>
          <option value="cash">Cash</option>
        </select>
        <input
          type="text"
          name="description"
          placeholder="Description"
          value={formData.description}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
          required
        />
        <input
          type="number"
          name="pointsRequired"
          placeholder="Points Required"
          value={formData.pointsRequired}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
          required
        />
        <input
          type="date"
          name="expiryDate"
          placeholder="Expiry Date"
          value={formData.expiryDate}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
        />
        <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded">
          Add Reward
        </button>
      </form>
    </div>
  );
}

export default AddReward;
