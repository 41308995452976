import { useState, useEffect } from 'react';
import API from '../../Services/api';
import L from 'leaflet';
import 'leaflet-control-geocoder';

function AddCollectionPoint() {
  const defaultItems = [
    { name: 'Answering Machines', points: 10 },
    { name: 'Laptops', points: 10 },
    { name: 'Automotive Electronics', points: 10 },
    // Add more items as needed
  ];

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    longitude: '',
    latitude: '',
    acceptedItems: defaultItems,
  });

  const [message, setMessage] = useState(null);
  const [isAddressValid, setIsAddressValid] = useState(true);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === 'address') {
      setIsAddressValid(true);  // Reset validity check on address change
    }
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...formData.acceptedItems];
    updatedItems[index][field] = value;
    setFormData({ ...formData, acceptedItems: updatedItems });
  };

  const handleAddNewItem = () => {
    setFormData({
      ...formData,
      acceptedItems: [...formData.acceptedItems, { name: '', points: 10 }],
    });
  };

  const handleRemoveItem = (index) => {
    const updatedItems = formData.acceptedItems.filter((_, i) => i !== index);
    setFormData({ ...formData, acceptedItems: updatedItems });
  };

  const handleGeocode = async () => {
    const geocoder = L.Control.Geocoder.nominatim();
    geocoder.geocode(formData.address, (results) => {
      if (results.length > 0) {
        const { lat, lng } = results[0].center;
        setFormData({ ...formData, latitude: lat, longitude: lng });
        setIsAddressValid(true);
      } else {
        setFormData({ ...formData, latitude: '', longitude: '' });
        setIsAddressValid(false);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await API.post(
        '/collection-points/add',
        {
          ...formData,
          acceptedItems: formData.acceptedItems.filter(
            (item) => item.name && item.points
          ),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setMessage('Collection point added successfully');
      setFormData({
        name: '',
        address: '',
        longitude: '',
        latitude: '',
        acceptedItems: defaultItems,
      });
    } catch (err) {
      console.error('Error adding collection point:', err);
    }
  };

  const isFormValid = formData.name && formData.address && formData.latitude && formData.longitude;

  useEffect(() => {
    if (formData.address) handleGeocode();
  }, [formData.address]);

  return (
    <div className="max-w-md mx-auto mt-10 p-5 border border-gray-200 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Add New Collection Point</h2>
      {message && <p className="text-green-500">{message}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
          required
        />
        <input
          type="text"
          name="address"
          placeholder="Address"
          value={formData.address}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
          required
        />
        {!isAddressValid && (
          <p className="text-red-500 mb-2">
            Address might be invalid. Manually input longitude and latitude.
          </p>
        )}
        <input
          type="number"
          name="longitude"
          placeholder="Longitude"
          value={formData.longitude}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
          required
        />
        <input
          type="number"
          name="latitude"
          placeholder="Latitude"
          value={formData.latitude}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
          required
        />

        <h3 className="text-xl font-semibold mb-2">Accepted Items & Points</h3>
        {formData.acceptedItems.map((item, index) => (
          <div key={index} className="flex items-center mb-3">
            <input
              type="checkbox"
              checked={!!item.name}
              onChange={() =>
                handleItemChange(index, 'name', item.name ? '' : defaultItems[index]?.name || '')
              }
              className="mr-2"
            />
            <input
              type="text"
              placeholder="Item name"
              value={item.name}
              onChange={(e) => handleItemChange(index, 'name', e.target.value)}
              className="flex-grow p-2 border rounded mr-2"
            />
            <input
              type="number"
              placeholder="Points"
              value={item.points}
              onChange={(e) => handleItemChange(index, 'points', e.target.value)}
              className="w-20 p-2 border rounded mr-2"
            />
            <button
              type="button"
              onClick={() => handleRemoveItem(index)}
              className="text-red-500 font-bold"
            >
              ×
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddNewItem}
          className="text-blue-500 font-semibold mb-4"
        >
          + Add New Item
        </button>

        <button
          type="submit"
          className={`w-full bg-blue-500 text-white py-2 rounded ${
            !isFormValid ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={!isFormValid}
        >
          Add Collection Point
        </button>
      </form>
    </div>
  );
}

export default AddCollectionPoint;
