import React, { useState } from 'react';
import API from '../../Services/api';
import { useNavigate } from 'react-router-dom';

const LoginStaff = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await API.post('/collection-staff/login', formData);
      console.log("Login response:", response.data);

      if (response.data.token) {
        localStorage.setItem('staff-token', response.data.token);
        console.log("Token stored:", localStorage.getItem('staff-token')); // Verify token is stored
        setMessage('Login successful');
        
        // Check if navigate is working correctly
        console.log("Navigating to /staff-dashboard");
        navigate('/staff-dashboard');
      } else {
        setMessage('Token missing from response');
      }
    } catch (error) {
      console.error("Login error:", error);
      setMessage(error.response?.data?.error || 'Login failed');
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className="w-full max-w-md p-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-semibold mb-4 text-center">Staff Login</h2>
        <input
          name="email"
          type="email"
          placeholder="Email"
          onChange={handleChange}
          required
          className="w-full px-4 py-2 mb-3 border rounded-md focus:outline-none focus:border-blue-500"
        />
        <input
          name="password"
          type="password"
          placeholder="Password"
          onChange={handleChange}
          required
          className="w-full px-4 py-2 mb-3 border rounded-md focus:outline-none focus:border-blue-500"
        />
        <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700">
          Login
        </button>
        {message && <p className="mt-4 text-center text-sm text-red-500">{message}</p>}
      </form>
    </div>
  );
};

export default LoginStaff;
