import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import CollectionPointMap from './CollectionPoints/CollectionPointMap';
import SubmitRequestPopup from './CollectionPoints/SubmitRequestPopup';
import Header from "./Header";
import Footer from "./Footer";

const FullPageMap = () => {
  const [userLocation, setUserLocation] = useState([51.520405, -0.10819]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false); 
  const [user, setUser] = useState(null);


  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const token = localStorage.getItem("token"); 
        if (token) {
          const decodedToken = jwtDecode(token);
          setUser(decodedToken); 
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Authentication check failed:", error);
        setIsAuthenticated(false);
      }
    };
    const images = Array.from(document.getElementsByTagName("img")); 
    const fonts = ["Inter", "Montserrat"];

    Promise.all([
      // Check if all images are loaded
      Promise.all(images.map((img) => {
        return new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
      })),
      // Check if all fonts are loaded
      Promise.all(fonts.map((font) => {
        return document.fonts.load(`1em ${font}`);
      })),
    ])
    .then(() => {
      setIsLoading(false); 
    })
    .catch((error) => {
      console.error("Error loading resources:", error);
      setIsLoading(false); // Hide spinner on error as well
    });

    checkAuthentication();
  }, []);
  const handleMarkerClick = (point) => {
    setSelectedPoint(point);
    setShowPopup(true);
  };
  window.addEventListener("message", (event) => {
    if (event.data === "loginComplete") {
      // Check if the user is authenticated
      const userId = localStorage.getItem("userId");
  
      if (userId) {
        // Handle actions you want to perform when login is complete, e.g., show a popup or display a message
        console.log("Login complete, user authenticated.");
        
        // If there was a pending recycling request, you can trigger any desired action here
        const recyclingRequest = localStorage.getItem("pendingRecyclingRequest");
        const requestExpiry = localStorage.getItem("requestExpiry");
  
        if (recyclingRequest && Date.now() < requestExpiry) {
          const parsedRequest = JSON.parse(recyclingRequest);
          console.log("Pending recycling request:", parsedRequest);
          // Optionally, show a modal or take some action in the original tab
        }
      }
    }
  });
  return (
    <div className="">
      {isLoading ? ( 
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-white bg-opacity-75">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-[#29752B]"></div>
        </div>
      ) : (<div className="h-screen">
      
      <div className="relative w-full bg-gray-100">
        <Header isDashboard={false} isAuthenticated={isAuthenticated} user={user}/>
        
        {/* Map Section */}
        <CollectionPointMap
          userLocation={userLocation}
          setUserLocation={setUserLocation}
          onMarkerClick={handleMarkerClick}
        />

        {/* Popup */}
        {showPopup && selectedPoint && (
          <div className="absolute top-0 left-0 w-full md:w-[400px] lg:w-[500px] z-[9999]">
            <SubmitRequestPopup
              onClose={() => setShowPopup(false)}
              collectionPoint={selectedPoint}
            />
          </div>
        )}

        
      </div>
      <Footer />
    </div>
      )}
    </div>
  );
};

export default FullPageMap;
