import React, { useState } from "react";
import CollectionPointMap from './CollectionPoints/CollectionPointMap';
import { Link } from "react-router-dom";

const Map = () => {
  const [userLocation, setUserLocation] = useState([51.520405, -0.10819]);

  return (
    <section className="bg-[#F8FAFB] py-20 px-6 md:px-8 overflow-hidden" id="map">
      <div className="container mx-auto">
        {/* Heading */}
        <h2 className="text-3xl md:text-4xl font-bold text-left" data-aos="fade-right" data-aos-delay="100">
          Find a Collection Point{" "}
          <span className="text-[#51B99D]">& Get Rewarded</span>
        </h2>

        {/* Map and Popup Container */}
        <div className="relative mt-8 w-full h-[700px]" data-aos="fade-up" data-aos-delay="200">
          {/* Clickable Overlay with Map */}
          <Link to="/map" className="block w-full h-full relative">
            {/* Map Section */}
            <div className="w-full h-full rounded-xl">
              <CollectionPointMap 
                userLocation={userLocation} 
                setUserLocation={setUserLocation} 
              />
            </div>

            {/* Overlay with the message */}
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-xl text-white text-xl font-bold z-[9999]">
              Click to view full map
            </div>
          </Link>
        </div>

        {/* Description */}
        <p className="text-gray-700 mt-8 text-xl tracking-wider" data-aos="fade-up" data-aos-delay="200">
          We’ve made recycling easy and rewarding! Use our Interactive E-Waste
          Map to find the nearest recycling centre that accepts your
          devices—and might even offer cash or store credit. Simply enter your
          location, and we’ll guide you to the closest drop-off point.
        </p>

        {/* Search Section
        <div className="mt-12 text-center">
          <p className="text-xl md:text-2xl font-semibold text-black mb-4" data-aos="fade-up" data-aos-delay="200">
            Ready to Recycle & Earn? Find a centre near you now:
          </p>
          <div className="flex w-full md:w-3/4 mx-auto rounded-lg overflow-hidden" data-aos="fade-up" data-aos-delay="300">
            <input
              type="text"
              placeholder="Enter location or city name"
              className="w-full px-4 py-3 border bg-white border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#59CBAC]"
              style={{ color: "#6E7C87" }}
            />
            <button className="bg-[#59CBAC] text-[#263D42] px-6 py-3 shrink-0 hover:bg-[#7de4af]">
              Search
            </button>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Map;
